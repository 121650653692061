import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
// import { ProgressBar } from 'react-bootstrap';

import moment from 'moment';
// import _ from 'lodash';

// import { useParams } from "react-router";

import NetInfo from "@react-native-community/netinfo";

import {
  osVersion, osName, browserName, fullBrowserVersion, isMobile
} from 'react-device-detect';

// import firebase, {
//   app, auth, firestore, collectionData, loggedIn$, firebaseConfig,
//   // dbCommon, dbLiveQuiz, dbQuestion, dbLogs
// } from './components/Firebase.js'

import firebase, {
  auth, firestore, firebaseConfig
} from './components/Firebase.js'

import Alert, { Btn, Feedback } from './components/AlertModal.js';
import { Lang, Locale } from './Localization/CustomLocalization.js';
import { UserLoginResults } from './components/UserLoginResults.js';
import { GlobalSetting } from './components/GlobalSetting.js';
import { CheckStringEmpty, CheckObjectNullValue, CheckObjectStringEmpty, Profile_ValidateProperties, CheckObjectNumber, CheckObjectBoolean, CheckBoolean, CapitalizeJsonKeys, CheckNullValue } from './components/GlobalFunctions.js';
import { Delay, DelayUntil } from './components/GlobalFunctions';      //2023.09.11

import './App.css';
import './App.scss';

// import { randomInt, randomUUID } from 'crypto';
// import AppContext, { CtxVal } from './AppContext.js';   //2022.06.17

// import TempProfileUids  from './components/TempProfileUids.js';

//////////=== Screens for Routing ===////////////////////////////////////////////

import BlankScreen from './Screens/BlankScreen.js';
// import BlankHome from './Screens/BlankHome.js';

// import SplashScreen from './Screens/SplashScreen.js';
import LoginScreen from './Screens/LoginScreen.js';
// import SignUpScreen from './Screens/SignUpScreen.js';
import PasswordResetScreen from './Screens/PasswordResetScreen.js';

import QuizHome from './Screens/Quiz/QuizHome.js';

import QuizLive from './Screens/Quiz/QuizLive.js';
// const PageComponent_QuizLive = lazy(() => import('../Quiz/QuizLive'));

import AdminHome from './Screens/Admin/AdminHome.js';
import QuizAdminTool from './Screens/Admin/QuizAdminTool.js';
import QuizManagement from './Screens/Admin/QuizManagement.js';
// import QuizHistoryHome from './Screens/Quiz/QuizHistoryHome.js';

//2020.11.24
import SignUpScreenV2 from './Screens/SignUpScreen_v2.js';
import ParticipatedEventList from './Screens/ParticipatedEventListScreen.js';

//2021.08.16
import DefaultEvents from './Screens/Quiz/DefaultEvents.js';
import GroupId from './Screens/Quiz/DefaultGroupId.js';
import SubjectId from './Screens/Quiz/DefaultSubjectId.js';

//2021.10.11
import QuizRoomTypeFileUpload from './Screens/Quiz/QuizRoomTypeFileUpload.js';
import QuizBankListAndEditDocRoomForSpecialWebPortal from './Screens/Admin/QM_ListAndEditDocRoomForAI.js';
import OrganizerChangePassword from './Screens/OrganizerChangePassword.js';

// import SchoolListWithCompleteDetails from './components/SchoolListWithCompleteDetails';
// import SchoolListWithDetails from './components/SchoolListWithDetails';

/////////////////////////////////////////////////////////////////////////////////

//2020.12.05
let Events = DefaultEvents;
let useDefaultEvents = false;

//#region //=== RTDB settings === start ===//

let firebaseConfig_PkQuiz = firebaseConfig;
firebaseConfig_PkQuiz.databaseURL = 'https://ikeyedutech-student-5ea3c-pkquiz.firebaseio.com/';
const app2 = firebase.initializeApp(firebaseConfig_PkQuiz, 'app2');

let firebaseConfig_Question = firebaseConfig;
firebaseConfig_Question.databaseURL = 'https://ikeyedutech-student-5ea3c-quizquestions.firebaseio.com/';
const app3 = firebase.initializeApp(firebaseConfig_Question, 'app3');

let firebaseConfig_Logs = firebaseConfig;
firebaseConfig_Logs.databaseURL = 'https://ikeyedutech-student-a28c5-logs.firebaseio.com/';
const app4 = firebase.initializeApp(firebaseConfig_Logs, 'app4');

//added on 2020.11.10
let firebaseConfig_ErrorLogs = firebaseConfig;
firebaseConfig_ErrorLogs.databaseURL = 'https://ikeyedutech-student-error-log.firebaseio.com/';
const app6 = firebase.initializeApp(firebaseConfig_ErrorLogs, 'app6');

//===== New Quiz Bank DB =====//
let firebaseConfig_QuizBank = firebaseConfig;
// firebaseConfig_QuizBank.databaseURL = 'https://ikey-quiz-bank-livequiz.firebaseio.com/';
firebaseConfig_QuizBank.databaseURL = 'https://ikey-quiz-bank-question-set.firebaseio.com/';
const app5 = firebase.initializeApp(firebaseConfig_QuizBank, 'app5');

//===== New RTDB - Live Quiz - Cert Serial Number Counter =====//
let firebaseConfig_CertSN = firebaseConfig;
firebaseConfig_CertSN.databaseURL = 'https://ikey-live-quiz-cert-sn-counter-6b20b.firebaseio.com/';
const app7 = firebase.initializeApp(firebaseConfig_CertSN, 'app7');

//added on 2023.09.09 - all latest question set - settings & questions.
let firebaseConfig_QuestionSet = firebaseConfig;
firebaseConfig_QuestionSet.databaseURL = 'https://ikey-quiz-bank-question-set.firebaseio.com/';
const app8 = firebase.initializeApp(firebaseConfig_QuestionSet, 'app8');

//added on 2023.09.14 - backup for student profile in FS.
let firebaseConfig_ProfileBackUp = firebaseConfig;
firebaseConfig_ProfileBackUp.databaseURL = 'https://ikeyedutech-student-profile.firebaseio.com/';
const app9 = firebase.initializeApp(firebaseConfig_ProfileBackUp, 'app9');

//added on 2023.10.30 - future main live quiz room result main root.
let firebaseConfig_LiveQuizResult = firebaseConfig;
firebaseConfig_LiveQuizResult.databaseURL = 'https://ikeyedutech-student-livequiz-result.firebaseio.com/';
const app10 = firebase.initializeApp(firebaseConfig_LiveQuizResult, 'app10');

//added on 2023.10.30 - future main live quiz room logs main root.
let firebaseConfig_LiveQuizLogs = firebaseConfig;
firebaseConfig_LiveQuizLogs.databaseURL = 'https://ikeyedutech-student-livequiz-log.firebaseio.com/';
const app11 = firebase.initializeApp(firebaseConfig_LiveQuizLogs, 'app11');

//added on 2023.10.30 - future logs main root.
let firebaseConfig_mLogs = firebaseConfig;
firebaseConfig_mLogs.databaseURL = 'https://ikeyedutech-student-log.firebaseio.com/';
const app12 = firebase.initializeApp(firebaseConfig_mLogs, 'app12');

//added on 2023.11.07 - future room result main root.
let firebaseConfig_RoomResult = firebaseConfig;
firebaseConfig_RoomResult.databaseURL = 'https://ikeyedutech-livequiz-room-result.firebaseio.com/';
const app13 = firebase.initializeApp(firebaseConfig_RoomResult, 'app13');

//all initiated RTDB root.
const dbCommon = firebase.database();
const dbLiveQuiz = firebase.database(app2);
const dbQuestion = firebase.database(app3);
const dbLogs = firebase.database(app4);
const dbQuizBank = firebase.database(app5);
const dbErrorLogs = firebase.database(app6);
const dbLQ_SN_Counter = firebase.database(app7);
const dbQuestionSet = firebase.database(app8);
const dbProfileBackUp = firebase.database(app9);
const dbLiveQuizResult = firebase.database(app10);        //2023.10.30
const dbLiveQuizLogs = firebase.database(app11);          //2023.10.30
const dbUserLogs = firebase.database(app12);              //2023.10.30
const dbLiveQuizRoomResult = firebase.database(app13);    //2023.11.07

//#endregion //=== RTDB settings === end ===//

let unsubscribe = null;

const Screen = {
  None: '',
  LoginScreen: 'LoginScreen',
  SignUpScreen: 'SignUpScreen',
  HomeScreen: 'HomeScreen',
  QuizLive: 'QuizLive',   //2023.10.18
  QuizManagementScreen: 'QuizManagementScreen',
};

let testing = null;

const DefaultCustomPermissionModel_Allowed = {
  view: true,
  search: true,
  download: true,
  upload: true,
  create: true,
  update: true,
  delete: true,
};
const DefaultCustomPermissionModel_Restricted = {
  view: false,
  search: false,
  download: false,
  upload: false,
  create: false,
  update: false,
  delete: false,
};
const DefaultCustomPermissions = [
  { manageRoom: { ...DefaultCustomPermissionModel_Allowed } },
  { manageQuestionSet: { ...DefaultCustomPermissionModel_Allowed } },
  { liveQuizRankingList: { ...DefaultCustomPermissionModel_Allowed } },

  { manageEvent: { ...DefaultCustomPermissionModel_Restricted } },
  { eventParticipationReport: { ...DefaultCustomPermissionModel_Restricted } },

  { manageStudentProfile: { ...DefaultCustomPermissionModel_Allowed } },
  { manageCustomGroup: { ...DefaultCustomPermissionModel_Allowed } },         //2023.11.28
  { manageStudentReport: { ...DefaultCustomPermissionModel_Allowed } },

  { manageAuthor: { ...DefaultCustomPermissionModel_Restricted } },
  { manageOrganizer: { ...DefaultCustomPermissionModel_Restricted } },    //2024.01.08
];
const AdminCustomPermissions = [
  { manageRoom: { ...DefaultCustomPermissionModel_Allowed } },
  { manageQuestionSet: { ...DefaultCustomPermissionModel_Allowed } },
  { liveQuizRankingList: { ...DefaultCustomPermissionModel_Allowed } },

  { manageEvent: { ...DefaultCustomPermissionModel_Allowed } },
  { eventParticipationReport: { ...DefaultCustomPermissionModel_Allowed } },

  { manageStudentProfile: { ...DefaultCustomPermissionModel_Allowed } },
  { manageCustomGroup: { ...DefaultCustomPermissionModel_Allowed } },         //2023.11.28
  { manageStudentReport: { ...DefaultCustomPermissionModel_Allowed } },

  { manageAuthor: { ...DefaultCustomPermissionModel_Allowed } },
  { manageOrganizer: { ...DefaultCustomPermissionModel_Allowed } },    //2024.01.08
];

class App extends Component {

  constructor(props) {
    super(props);

    this.LoginScreen_Ref = React.createRef();
    this.SignUpScreen_Ref = React.createRef();
    this.QuizHome_Ref = React.createRef();
    this.QuizLive_Ref = React.createRef();  //2023.10.18
  }

  state = {
    LastUpdateDate: '2024-12-16 13:25:00',
    PreviousUpdateDate: '',

    Locale: Lang.English,
    isDevMode: window.location.href.includes('localhost'),

    user: null,
    isLoginDone: false,   //2023.10.31
    preActiveUserUid: '',
    isNewUser: false,
    profile: null,
    isSuperAdmin: false,
    isAuthor: false,

    email: '',
    password: '',

    // isSplashDelayDone: false,
    errorMessage: '',

    quizRoomCode: '',
    quizRoomId: '',
    quizRoomDate: '',   //2021.07.14
    historyList: [],
    viewHistoryQuiz: null,

    timeLeftText: '',

    isRealtimeProfileExists: false,
    isFireStoreProfileExists: false,
    // userProfile_RTDB: {},
    // userProfile_FS: {},

    //2020.11.25
    isHistoryListSyncDone: false,
    isHistoryListExists_Local: false,
    isHistoryListExists_FS: false,
    // isHistoryListLoaded: false,
    // LU_HistoryList_Local: '',
    // LU_HistoryList_FS: '',

    //2020.11.26
    isFromParentApp: false,
    isRedirectToHistoryList: false,

    //2020.11.27
    isPolicyTncStateTheLatest: false,
    policyTncVersion_PublishedDate: '2020.12.05',   //2020.12.05 first published date.

    // //2020.11.30
    // Build_Last_LU_Check: '',
    isInternetReachable: true,

    //2021.08.16
    EventList: [],
    EventNotAvailable: false,
    IsActiveEventListLoaded: false,   //2021.09.15

    //2020.12.03
    isEventRegistration: false,
    eventName: '',
    eventCode: '',  //2021.01.08
    centerName: '',
    centerDisplayName: '',  //2021.03.18
    eventModal: null, //2021.02.10
    uniqueEventCode: '',  //'b7192cbc98da4145a5f1e282e3a65f84', //2021.07.01

    //2022.06.03
    evt_centerUserId: 0,
    evt_authorId: 0,

    //2020.12.11
    reEnterQuizRoom: false,

    //2021.02.01
    currentScreen: Screen.None,

    //2021.04.08
    // visible: false,

    //2021.04.12
    isFromApp_ParticipatedEvent: false,

    //2021.07.14
    OrganizerIdentity: '',

    // //2021.07.24
    // MobileSignUpPageReturnBypass: false,

    //2021.07.27
    LoginAsAuthor: false,

    //2021.08.18
    Credential: null,
    LoginState: { Success: false, ErrorCode: '', Message: '' },

    //2022.06.16
    IsNewUpdate: false,

    //2022.10.22
    PageComponent_QuizLive: null,

    // //2022.11.01
    // GenderOptions: [],
    // RaceOptions: [],

    admin_processing: false,  //2023.11.07
  }

  componentDidMount = async () => {

    // console.log(moment.utc('2023-11-16 05:06:23').local().format('lll'));

    // let d = moment.utc();
    // let dd = d.toDate()
    // console.log(dd);
    // console.log(moment(dd).format('YYYY-MM-DD HH:mm:ss ZZ'));

    // console.log('time = ' + moment('Fri Oct 20 2023 04:52:21 GMT+0000').format('YYYY-MM-DD HH:mm:ss'));
    //output: '2023-10-20 12:52:21' = from utc to current local time.
    // console.log('time (utc) = ' + moment('Fri Oct 20 2023 04:52:21 GMT+0000').utc().format('YYYY-MM-DD HH:mm:ss'));
    //output: '2023-10-20 04:52:21' = remain as utc.

    // console.log('tz = ' + moment().format('Z'));      //e.g. 'Z' ="+8:00" , 'ZZ' = "+0800"
    // console.log('tz = ' + moment.utc().format('Z'));  //e.g. 'Z' ="+8:00" , 'ZZ' = "+0800"

    // console.log('\n SchoolListWithCompleteDetails = ' + SchoolListWithCompleteDetails.length);
    // console.log('\n SchoolListWithDetails = ' + SchoolListWithDetails.length);


    // const appCtx = useContext(AppContext);
    // testing = setInterval(() => {
    //   appCtx.setValue(CtxVal.EventCode, randomUUID());
    //   appCtx.setValue(CtxVal.RoomCode, randomInt(5));
    // }, 1000);

    // let testString = "!@#$%^&*()_+-=;:',./<>{}[]|\/~`" + ' "中文字1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    // console.log(testString);
    // console.log(testString.replaceAll(/[$-/:-?{-~!"^_`\[\]\@\#\s]/g, '-').toLowerCase());

    //#region old codes

    // let rightNow = moment();
    // console.log(
    //   'Now = ' + rightNow.format('YYYY-MM-DD HH:mm:ss')
    //   + '\n' + rightNow.valueOf()
    //   + '\n' + moment(1630730727212).format('YYYY-MM-DD HH:mm:ss')
    // );
    // console.log(6490/2000);

    // let _ContactNumber = '+60128202769111111'
    // let _sliced = _ContactNumber.slice(15, _ContactNumber.length);
    // let extra = _sliced.length;
    // console.log(_ContactNumber + '\n' + _ContactNumber.length + '\n\nsliced: ' + _sliced + '\nextra: ' + extra);

    // //testing & demo view sample purpose only.
    // let openNewWindow = (link) => {
    //   return "window.open('" + link + "')";
    // }
    // // let message = Locale("auto-reg-success-message", this.props.Locale);
    // let message = "<span style='font-size:18px;font-weight:bold;'>Account Successfully Created!</span><br />";
    // message += "You can also use this email and password to login iKEY App to access the completed live quiz results and certificates.";
    // message += "<br /><br /><span style='font-size:16px;font-weight:bold;'><table cellpadding='5' cellspacing='0' width='100%' border='0'><tbody>";
    // message += "<tr><td colspan='3' align='center'>Download the iKEY App now:</td></tr>";
    // message += "<tr>";
    // message += "<td align='right'><button class='border-0' style='outline:0px;background-color:transparent;' onclick=" + openNewWindow('https://apps.apple.com/us/app/ikey-parent/id1476014901') + ">";
    // message += "<img src='https://ikeynew.blob.core.windows.net/ikeykidz/app_store_logo.png' alt='app_store_logo' width='100%' /></button></td>";
    // message += "<td>&nbsp;&nbsp;</td>";
    // message += "<td><button class='border-0' style='outline:0px;background-color:transparent;' onclick=" + openNewWindow('https://play.google.com/store/apps/details?id=my.com.ikeyedutect.parent.full') + ">";
    // message += "<img src='https://ikeynew.blob.core.windows.net/ikeykidz/google_play_logo.png' alt='google_play_logo' width='100%' /></button></td>";
    // message += "</tr>";
    // // message += "<tr><td colspan='3' align='center'>to download.</td></tr>";
    // message += "</tbody></table></span>";
    // this.SetAlertConfirm('Notice',
    //   // Locale("auto-reg-success-message", this.props.Locale),
    //   message,
    //   Feedback.Confirm, false, false, true, false, false);


    // setTimeout(() => {
    //   this.setState({ isSplashDelayDone: true });
    // }, 2000);

    // if (isMobile) {
    //   // if (this.state.MobileSignUpPageReturnBypass === false) {
    //   //   this.setState({ MobileSignUpPageReturnBypass: true });
    //   //   this.LoginScreen_Ref.current.GotoSignUp();
    //   //   return null;
    //   // }
    // }

    // if (isMobile) {
    //   let check = localStorage.getItem('MobileSignUpPageReturnBypass');
    //   // if (check !== null)
    //   //   localStorage.removeItem('MobileSignUpPageReturnBypass');
    //   if (check === null)
    //     localStorage.setItem('MobileSignUpPageReturnBypass', '');
    //   else
    //     if (check !== '')
    //       this.SetOrganizerIdentity(check);
    // }

    // if (isMobile) {
    //   alert(window.location.href);
    // }

    // //2021.07.29
    // unsubscribe = NetInfo.addEventListener(state => this.CheckInternetReachability(state));

    // //2021.07.24
    // if (isMobile) {
    //   localStorage.setItem('MobileSignUpPageReturnBypass', 'none');
    // }

    //#endregion

    this.SetLoading('', 'loading...', false);

    this.LoadLocalizatioLanguageSetting();

    // //2021.04.06
    // useDefaultEvents = window.location.href.toString().includes('localhost');
    // this.setState({
    //   isDevMode: useDefaultEvents,
    // });

    //2021.12.07
    useDefaultEvents = false;
    this.setState({
      isDevMode: window.location.href.toString().includes('localhost'),
    });

    // setTimeout(() => {
    //   let text = '';

    //   // text = window.location.href;
    //   text = 'href = ' + window.location.href + '\n'
    //     + 'hostname = ' + window.location.hostname + '\n'
    //     + 'hash = ' + window.location.hash + '\n'
    //     + 'protocol = ' + window.location.protocol + '\n'
    //     + 'pathname = ' + window.location.pathname + '\n'
    //     + 'host = ' + window.location.host + '\n'
    //     + 'search = ' + window.location.search + '\n'
    //     + 'origin = ' + window.location.origin + '\n'
    //     + 'port = ' + window.location.port + '\n'
    //     ;

    //   alert(text);
    // }, 2000);


    //2020.11.30 = moved here from LoginScreen.
    //Check Last Update & todo Auto-reload webpage if needed.
    await this.CheckLastUpdate();
    window.addEventListener("focus", this.CheckLastUpdate);
    // window.addEventListener("beforeunload", this.OnUnload);

    //2020.11.30
    unsubscribe = NetInfo.addEventListener(state => this.CheckInternetReachability(state));

    //=== 2021.04.08 - custom right-click popup menu === start
    document.addEventListener('contextmenu', this._handleContextMenu);
    // document.addEventListener('click', this._handleClick);
    // document.addEventListener('scroll', this._handleScroll);
    document.addEventListener('keydown', this._keyupHandler);
    //=== 2021.04.08 - custom right-click popup menu === end

    // //2021.08.16
    // await this.LoadAllEvents();

    // //2022.06.16
    // const lastUpdateDT = localStorage.getItem('LQP_LU');
    // if (lastUpdateDT === null || lastUpdateDT !== this.state.LastUpdateDate) {
    //   this.setState({ IsNewUpdate: true, });
    //   localStorage.setItem('LQP_LU', this.state.LastUpdateDate);
    // }

    //2023.10.25
    const previousUpdateDT = localStorage.getItem('LQP_PU');
    const lastUpdateDT = localStorage.getItem('LQP_LU');
    // console.log('lastUpdateDT', lastUpdateDT);
    // console.log('previousUpdateDT', previousUpdateDT);
    if (lastUpdateDT === null || lastUpdateDT !== this.state.LastUpdateDate) {
      this.setState({ IsNewUpdate: true, });
      localStorage.setItem('LQP_LU', this.state.LastUpdateDate);
      if (CheckStringEmpty(previousUpdateDT) === '' || moment(this.state.LastUpdateDate) > moment(previousUpdateDT)) {
        localStorage.setItem('LQP_PU', lastUpdateDT);
        this.setState({ PreviousUpdateDate: lastUpdateDT, });
      }
      else {
        this.setState({ PreviousUpdateDate: previousUpdateDT, });
      }
    }
    else {
      this.setState({ PreviousUpdateDate: CheckStringEmpty(previousUpdateDT), });
    }
    // localStorage.removeItem('LQP_PU');

    //2021.09.01
    await this.LoadActiveEventsOnly(useDefaultEvents);
    // await this.LoadAllEvents(false);

    // this.SetAlert('testing', ['test1', 'test2', 'test3'].join('<br />'));

    // //2022.11.01
    // this.ResetOptionsLabel();
  }

  componentWillUnmount = () => {

    clearInterval(testing);

    window.removeEventListener("focus", this.CheckLastUpdate);
    // window.removeEventListener("beforeunload", this.OnUnload);
    // localStorage.removeItem("Build_Last_LU_Check");
    unsubscribe();
    // NetInfo.removeEventListener();

    //=== 2021.04.08 - custom right-click popup menu === start
    document.removeEventListener('contextmenu', this._handleContextMenu);
    // document.removeEventListener('click', this._handleClick);
    // document.removeEventListener('scroll', this._handleScroll);
    document.removeEventListener('keyup', this._keyupHandler);
    //=== 2021.04.08 - custom right-click popup menu === end

    // //2021.07.24
    // if (isMobile) {
    //   localStorage.setItem('MobileSignUpPageReturnBypass', '');
    // }

    // //2021.08.25
    // localStorage.removeItem('SelectedEventCode');
  }

  //2020.11.30
  // sleep = (delay) => {
  //   let start = new Date().getTime();
  //   while (new Date().getTime() < start + delay);
  // }

  // //2020.11.30
  // OnUnload = (ev) => {
  //   ev.preventDefault();
  //   return ev.returnValue = '';

  //   // window.removeEventListener("focus", this.CheckLastUpdate);
  //   // window.removeEventListener("beforeunload", this.OnUnload);
  //   // localStorage.removeItem("Build_Last_LU_Check");

  //   // let confirmExit = window.confirm('Are you sure want to exit ?');
  //   // if (confirmExit) {
  //   //   return ev.returnValue = '';
  //   // }

  //   // this.SetAlert("Confirm ?", "Are you sure want to leave this page ?");
  // }

  // SetMobileSignUpPageReturnBypass = (value) => {
  //   this.setState({ MobileSignUpPageReturnBypass: value });
  // }

  //2021.11.19
  SetEventNotAvailable = (value) => {
    this.setState({ EventNotAvailable: value, }, () => {
      if (this.state.isDevMode)
        console.log('EventNotAvailable = ' + this.state.EventNotAvailable)
    });
  }

  //2021.09.01
  LoadActiveEventsOnly = async (_useDefaultEvents = useDefaultEvents) => {
    this.SetLoading('', 'fetching event list...', false);
    if (_useDefaultEvents) {
      // Events = DefaultEvents;
      let _eventNotAvailable = true;
      DefaultEvents.map((data, key) => {
        if (moment() <= moment(data.RegistrationEnd)) {
          _eventNotAvailable = false;
          // console.log(JSON.stringify(data));
        }
        return null;
      });
      this.setState({
        IsActiveEventListLoaded: true,
        EventNotAvailable: _eventNotAvailable,
      }, () => {
        if (this.state.isDevMode)
          console.log('EventNotAvailable (dev) = ' + this.state.EventNotAvailable);
      });
      this.CloseAlert();
      return null;
    }
    let eventNotAvailable = true;
    let eventList = [];
    if (this.state.isDevMode === false) {
      //#region old code - revamped at below 2021.11.19
      // do {
      //   await firestore
      //     .collection("LiveQuiz_UpcomingEvents")
      //     // .where('Role', '==', 'Global')
      //     // .where('Active', '==', true)
      //     .where('RegistrationEnd', '>=', moment().format('YYYY-MM-DD 00:00:00'))
      //     // .where('IsPublic', '==', false)
      //     .orderBy('RegistrationEnd', 'asc')
      //     .get()
      //     .then((querySnapshot) => {
      //       let data = [];
      //       if (querySnapshot !== null) {
      //         querySnapshot.forEach((doc) => {
      //           data.push(doc.data());
      //         });
      //       }
      //       if (data.length > 0) {
      //         if (data[data.length - 1].hasOwnProperty('EventCode')) {
      //           eventNotAvailable = false;
      //         }
      //         eventList = data;
      //         if (this.state.isDevMode) {
      //           console.log(data.length);
      //           console.log(JSON.stringify(data));
      //         }
      //       }
      //     });
      //   await Delay(1000);
      //   if (eventList.length === 0) {
      //     this.SetAlert('', 'bad Internet connection detected.<br />retrying...', false);
      //   }
      // } while (eventList.length === 0);
      //#endregion

      //2021.11.19 - revamped.
      let retryCounter = 0;
      let retryAgain = false;
      let done = false;
      let async_action = async () => {
        await firestore
          .collection("LiveQuiz_UpcomingEvents")
          // .where('Role', '==', 'Global')
          .where('Active', '==', true)
          .where('RegistrationEnd', '>=', moment().format('YYYY-MM-DD 00:00:00'))
          // .where('IsPublic', '==', false)
          .orderBy('RegistrationEnd', 'asc')
          .get()
          .then((querySnapshot) => {
            let data = [];
            if (querySnapshot !== null) {
              querySnapshot.forEach((doc) => {
                data.push(doc.data());
              });
            }
            if (data.length > 0) {
              // if (data[data.length - 1].hasOwnProperty('EventCode')) {
              //   eventNotAvailable = false;
              // }
              eventList = data;

              //remove testing event - 2021.11.19
              let findIndex = eventList.findIndex(x => x.EventCode === 'ikeyteam_2021_beta_test_event');
              if (this.state.isDevMode === false)
                if (findIndex > -1)
                  eventList.splice(findIndex, 1);
              eventNotAvailable = eventList.length === 0;

              if (this.state.isDevMode) {
                console.log('event qty = ' + eventList.length + (findIndex > -1 ? '\n(admin testing event (1 = ikeyteam_2021_beta_test_event))' : ''));
                console.log('events =\n' + JSON.stringify(eventList));
              }
            }
            done = true;
          })
          .catch(error => {
            retryAgain = true;
            done = true;
            if (this.state.isDevMode)
              console.log('fetch active events (failed) =\n' + error.message);
          });
        await DelayUntil(() => done === true);
      }
      do {
        retryAgain = false;
        done = false;
        // console.log('\n start = ' + moment().format());
        await async_action();
        // await DelayUntil(() => done === true);
        // console.log('\n end = ' + moment().format());
        await Delay(1000);
        if (eventList.length === 0) {
          // this.SetAlert('', 'bad Internet connection detected.<br />retrying...', false);
          console.log('no live event(s) found.');
        }
        if (retryAgain) {
          retryCounter++;
        }
        if (retryCounter > 2) {
          retryAgain = false;
          this.SetAlert('', 'bad Internet connection detected.<br />reloading page in 3 sec', false);
          await Delay(3000);
          window.location.reload();
        }
      } while (retryAgain);
    }
    this.CloseAlert();

    this.setState({
      EventList: eventList,
      EventNotAvailable: eventNotAvailable,
      IsActiveEventListLoaded: true,
    }, () => {
      Events = this.state.EventList;  //replace default Events list.
      if (this.state.isDevMode) {
        // console.log(JSON.stringify(eventList));
        console.log('EventNotAvailable = ' + this.state.EventNotAvailable);
        console.log('IsActiveEventListLoaded = ' + this.state.IsActiveEventListLoaded);
      }
    });
  }

  //2021.08.16
  LoadAllEvents = async (_useDefaultEvents = useDefaultEvents) => {
    if (_useDefaultEvents) {
      // Events = DefaultEvents;
      return null;
    }

    let eventNotAvailable = true;
    let eventList = [];
    await firestore
      .collection("LiveQuiz_UpcomingEvents")
      .orderBy('DateStart', 'desc')
      // .limit(8)
      .get()
      .then((querySnapshot) => {
        let data = [];
        if (querySnapshot !== null) {
          querySnapshot.forEach((doc) => {
            data.push(doc.data());
          });
        }
        if (data.length > 0) {
          eventList = data;
          // console.log(JSON.stringify(data));
          eventNotAvailable = false;
        }
      });
    this.setState({
      EventList: eventList,
      EventNotAvailable: eventNotAvailable,
    }, () => {
      Events = this.state.EventList;  //replace default Events list.
      // console.log(JSON.stringify(eventList));
    });
  }

  //2021.09.01
  ResetEventList = () => {
    this.setState({ EventList: [], });
  }

  //2021.07.27
  SetLoginAsAuthor = (value) => {
    this.setState({ LoginAsAuthor: value });
  }

  //2021.07.14
  SetOrganizerIdentity = (value) => {
    this.setState({ OrganizerIdentity: value });

    if (this.state.isDevMode)
      console.log(this.state.OrganizerIdentity);
  }

  SetReEnterQuizRoom = (option) => {
    this.setState({
      reEnterQuizRoom: option,
    });
  }

  //2020.11.30 = moved here from LoginScreen.
  CheckLastUpdate = async () => {

    if (this.state.isDevMode)
      return null;

    let proceedToCheckLastUpdate = true;

    let _last_LU_Check = localStorage.getItem('Build_Last_LU_Check', '2020-11-30 00:00:00');
    let _last_LU_Check_DT = null;

    //only check for new build if current time is X minutes over than last check time.
    // _last_LU_Check_DT = moment(_last_LU_Check).add(3, 'minutes');
    _last_LU_Check_DT = moment(_last_LU_Check, 'YYYY-MM-DD HH:mm:ss').add(10, 'seconds');

    if (_last_LU_Check_DT !== null)
      if (moment() < _last_LU_Check_DT)
        proceedToCheckLastUpdate = false;

    // this.SetAlert('Next Build Check', _last_LU_Check_DT.format('YYYY-MM-DD HH:mm:ss'));

    let autoReload = false;

    //2022.06.09
    if (proceedToCheckLastUpdate) {

      let _lastUpdate_fetched = '';

      await dbCommon.ref('Version/LiveQuiz/Web/LiveQuiz/LastUpdate')
        .once('value', snapshot => {
          if (snapshot.exists()) {
            _lastUpdate_fetched = String(snapshot.val());
          }
        });

      if (_lastUpdate_fetched.length > 0) {
        //Auto-Reload the webpage if found WebApp's LU is older than fetched LU.
        if (moment(this.state.LastUpdateDate, 'YYYY-MM-DD HH:mm:ss') < moment(_lastUpdate_fetched, 'YYYY-MM-DD HH:mm:ss')) {

          let _msg = 'A New Build is available, user is advised to click OK to reload this page right now.\n\n* New Build available (' + _lastUpdate_fetched + ')';

          // let primaryCheckDone = false;
          // if (this.state.LoginAsAuthor) {

          //   primaryCheckDone = true;
          // }
          // else {

          //   primaryCheckDone = true;
          // }

          let confirmReload = window.confirm(_msg);
          if (confirmReload) {
            autoReload = true;
          }
          //end.  
        }
      }

      //force reload if build version older than 1 day.
      if (this.state.isDevMode === false) {
        if (moment() > moment(_lastUpdate_fetched, 'YYYY-MM-DD HH:mm:ss').add(1, 'day') && this.state.LastUpdateDate < _lastUpdate_fetched) {
          window.alert('Current build is obsolete for too long, force reload will be carry out immediately.');
          autoReload = true;
        }
      }

      let _current = moment().format('YYYY-MM-DD HH:mm:ss');
      localStorage.setItem("Build_Last_LU_Check", _current);
      if (this.state.isDevMode)
        console.log('Build Last Check = ' + _current);
      this.AutoReloadToFirstPage(autoReload);   //2021.12.28
    }

    //#region old codes
    // if (proceedToCheckLastUpdate) {

    //   let _lastUpdate_fetched = '';

    //   // let _error = '';

    //   //2020.11.30 = not using FireStore due to global security setting limitation.
    //   // await firestore
    //   //   .collection('LastUpdate')
    //   //   .where('Id', '==', 'LiveQuizWeb')
    //   //   .get()
    //   //   .then((querySnapshot) => {
    //   //     let data = [];
    //   //     if (querySnapshot !== null) {
    //   //       querySnapshot.forEach((doc) => {
    //   //         data.push(doc.data());
    //   //       });
    //   //     }
    //   //     if (data.length > 0) {
    //   //       _lastUpdate_fetched = data[0]['Date'];
    //   //     }
    //   //   })
    //   //   .catch(async (error) => {
    //   //     // await this.SetErrorLog(new Date(), "LoginScreen | CheckLastUpdate | Error = " + error);
    //   //     _error = error;
    //   //   });

    //   await dbCommon.ref('Version/LiveQuiz/Web/LiveQuiz/LastUpdate')
    //     .once('value', snapshot => {
    //       if (snapshot.exists()) {
    //         _lastUpdate_fetched = String(snapshot.val());
    //       }
    //     });
    //   // .catch(async (error) => {
    //   //   // await this.SetErrorLog(new Date(), "LoginScreen | CheckLastUpdate | Error = " + error);
    //   //   // _error = error;
    //   // });

    //   if (_lastUpdate_fetched.length > 0) {
    //     // let autoReload = false;
    //     //Auto-Reload the webpage if found WebApp's LU is older than fetched LU.
    //     if (moment(this.state.LastUpdateDate, 'YYYY-MM-DD HH:mm:ss') < moment(_lastUpdate_fetched, 'YYYY-MM-DD HH:mm:ss')) {
    //       // alert(this.state.LastUpdateDate
    //       //   + '\n\n' + _lastUpdate_fetched
    //       //   + '\n\n' + String(moment(this.state.LastUpdateDate) < moment(_lastUpdate_fetched)));
    //       // window.location.reload();

    //       // if (window.location.href.includes('livequiz-2')) {
    //       //   window.location.href = "https://livequiz-2.ikeyedutech.com.my/";
    //       // }
    //       // else {
    //       //   window.location.href = "https://livequiz.ikeyedutech.com.my/";
    //       // }

    //       //2021.09.06 - revamped.
    //       // let _repeatNotice = true;
    //       let _msg = 'A New Build is available, user is advised to click OK to reload this page right now.\n\n* New Build available (' + _lastUpdate_fetched + ')';
    //       // do {

    //       let confirmReload = window.confirm(_msg);
    //       if (confirmReload) {
    //         // _repeatNotice = false;
    //         // let _organizerRedirection = String(window.location.href).includes(String(this.state.OrganizerIdentity)) ? this.state.OrganizerIdentity : '';

    //         // if (window.location.href.includes('localhost')) {
    //         //   window.location.reload();
    //         // }
    //         // else if (window.location.href.includes('livequiz-2')) {
    //         //   window.location.href = "https://livequiz-2.ikeyedutech.com.my/";
    //         //   // + _organizerRedirection;
    //         // }
    //         // else {
    //         //   window.location.href = "https://livequiz.ikeyedutech.com.my/";
    //         //   // + _organizerRedirection;
    //         // }
    //         autoReload = true;
    //       }
    //       else {
    //         //force reload if build version older than 1 day.
    //         if (this.state.isDevMode === false)
    //           if (moment() > moment(_lastUpdate_fetched, 'YYYY-MM-DD HH:mm:ss').add(1, 'day') && this.state.LastUpdateDate !== _lastUpdate_fetched) {
    //             window.alert('Current build is obsolete for too long, force reload will be carry out immediately.');
    //             autoReload = true;
    //           }
    //       }
    //       //   else {
    //       //     await Delay(5000);
    //       //   }
    //       // } while (_repeatNotice);
    //       //end.  
    //     }
    //     else {
    //       //force reload if build version older than 1 day.
    //       if (this.state.isDevMode === false)
    //         if (moment() > moment(_lastUpdate_fetched, 'YYYY-MM-DD HH:mm:ss').add(1, 'day') && this.state.LastUpdateDate !== _lastUpdate_fetched) {
    //           window.alert('Current build is obsolete for too long, force reload will be carry out immediately.');
    //           autoReload = true;
    //         }
    //     }

    //     // if (autoReload) {
    //     //   if (window.location.href.includes('localhost')) {
    //     //     window.location.reload();
    //     //   }
    //     //   else if (window.location.href.includes('livequiz-2')) {
    //     //     window.location.href = "https://livequiz-2.ikeyedutech.com.my/";
    //     //     // + _organizerRedirection;
    //     //   }
    //     //   else {
    //     //     window.location.href = "https://livequiz.ikeyedutech.com.my/";
    //     //     // + _organizerRedirection;
    //     //   }
    //     // }

    //     // this.AutoReloadToFirstPage(autoReload);   //2021.12.28
    //   }

    //   // alert(
    //   //   "Current Build LU = " + this.state.LastUpdateDate + "\n\n"
    //   //   + "System Build LU = " + _lastUpdate_fetched
    //   //   + "\n\n Error = " + _error
    //   // );

    //   // this.SetAlert('Last Update',
    //   //   "Current Build LU = " + this.state.LastUpdateDate + "<br />"
    //   //   + "Server Build LU = " + _lastUpdate_fetched + "<br /><br />"
    //   //   + "Error = " + _error
    //   // );

    //   // this.SetAlertConfirm('Last Update',
    //   //   "Build_Last_LU_Check = " + _last_LU_Check + "<br />"
    //   //   + "Build_Last_LU_Check (DT) = " + _last_LU_Check_DT.format('YYYY-MM-DD HH:mm:ss') + "<br />"
    //   //   + "Current (DT) = " + moment().format('YYYY-MM-DD HH:mm:ss') + "<br /><br />"

    //   //   + "Current Build LU = " + this.state.LastUpdateDate + "<br />"
    //   //   + "Server Build LU = " + _lastUpdate_fetched + "<br /><br />"
    //   //   + "Error = " + _error
    //   //   , Feedback.InternetDown
    //   //   , false, false, true, false, false);

    //   let _current = moment().format('YYYY-MM-DD HH:mm:ss');
    //   localStorage.setItem("Build_Last_LU_Check", _current);
    //   if (this.state.isDevMode)
    //     console.log('Build Last Check = ' + _current);
    //   this.AutoReloadToFirstPage(autoReload);   //2021.12.28
    // }
    //#endregion old codes

    this.CheckLoginLifeSpan();
    // console.log(localStorage.getItem('Login_DT', 'no data'));
  }

  //2021.12.28
  CheckLoginLifeSpan = () => {

    if (this.state.isDevMode)
      return null;

    if (this.state.user !== null && this.state.user !== undefined) {
      let _last_login_dt = localStorage.getItem('Login_DT', null);
      if (_last_login_dt !== null && _last_login_dt !== undefined && _last_login_dt !== '') {
        let autoReload = moment() > moment(_last_login_dt, 'YYYY-MM-DD HH:mm:ss').add(1, 'day');
        this.AutoReloadToFirstPage(autoReload);

        if (this.state.isDevMode)
          console.log('\nautoReload = ' + autoReload
            + '\nlogin dt = ' + _last_login_dt
            + '\nlast + 1d = ' + moment(_last_login_dt, 'YYYY-MM-DD HH:mm:ss').add(1, 'day').toString()
            + '\nnow = ' + moment().toString());
      }
    }
  }

  //2021.12.28
  AutoReloadToFirstPage = (autoReload = false) => {
    if (autoReload) {
      if (window.location.href.includes('localhost')) {
        window.location.reload();
      }
      else if (window.location.href.includes('livequiz-2')) {
        window.location.href = "https://livequiz-2.ikeyedutech.com.my/";
        // + _organizerRedirection;
      }
      else {
        window.location.href = "https://livequiz.ikeyedutech.com.my/";
        // + _organizerRedirection;
      }
    }
  }

  //2020.11.30
  CheckInternetReachability = (error) => {
    // this.SetAlert('Internet Reachability', error.message);

    if (error.isInternetReachable === null && error.isConnected === true && this.state.isInternetReachable === false) {
      this.SetLoading(
        Locale("label-internet-recovering", this.props.Locale),
        Locale("text-internet-recovering", this.props.Locale),
        true);
    }
    else if (error.isInternetReachable === false && error.isConnected === false) {
      this.SetAlert(
        Locale("label-internet-down", this.props.Locale),
        Locale("text-internet-down", this.props.Locale),
      );
      this.setState({
        isInternetReachable: false,
      });
    }
    else if (error.isInternetReachable === true && error.isConnected === true && this.state.isInternetReachable === false) {
      this.SetAlert(
        Locale("label-internet-reconnected", this.props.Locale),
        Locale("text-internet-reconnected", this.props.Locale),
      );
      this.setState({
        isInternetReachable: true,
      }, () => {
        setTimeout(() => {
          this.CloseAlert();
        }, 1000);
      });
    }

  }

  SetSuperAdmin = (setting) => {
    this.setState({
      isSuperAdmin: CheckBoolean(setting),
    });
  }

  SetAuthor = (setting) => {
    this.setState({
      isAuthor: CheckBoolean(setting),
    });
  }

  SetIsFromParentApp = (setting) => {
    this.setState({
      isFromParentApp: setting,
    });
  }

  //2021.04.12
  SetIsFromAppParticipatedEvent = (setting) => {
    this.setState({
      isFromApp_ParticipatedEvent: setting,
    });
  }

  SetIsRedirectToHistoryList = (setting) => {
    this.setState({
      isRedirectToHistoryList: setting,
    });
  }

  SetIsPolicyTncStateTheLatest = (setting) => {
    this.setState({
      isPolicyTncStateTheLatest: setting,
    });
  }

  //2022.06.03
  //2021.04.06
  SetEventInfo = (eventCode, centerUserId = 0, authorId = 0) => {
    this.setState({
      eventCode: eventCode,

      //2022.06.03
      evt_centerUserId: centerUserId > 0 ? String(centerUserId) : null,
      evt_authorId: authorId > 0 ? String(authorId) : null,
    });
  }

  //2021.04.06
  CheckIfRoomBelongsToEvent = (roomCode) => {
    let eventCode = '';
    let isFound = false;
    // let Events = this.state.Events;
    let eventModal = null;  //2021.07.05
    Events = this.state.EventList;  //2022.06.15
    for (let x = 0; x < Events.length; x++) {
      for (let y = 0; y < Events[x].QuizRooms.length; y++) {
        for (let z = 0; z < Events[x].QuizRooms[y].Rooms.length; z++) {
          if (String(Events[x].QuizRooms[y].Rooms[z].RoomCode) === String(roomCode)) {
            eventCode = Events[x].EventCode;
            eventModal = Events[x];   //2021.07.05
            isFound = true;
            break;
          }
        }
        if (isFound)
          break;
      }
      if (isFound)
        break;
    }
    if (this.state.isDevMode)
      console.log(isFound ? '*** this room belongs to an event.\n<' + eventModal.EventName + '> ***' : '*** this room is not related to any event. ***');

    if (isFound) {
      this.SetIsEvent(true, eventCode);

      if (this.state.isDevMode)
        console.log('*** ' + eventCode + ' ***');
    }
    return { Result: isFound, EventCode: eventCode, EventModal: eventModal };
  }

  SetIsUniqueEvent = (_isUniqueEvent, _uniqueEventCode) => {
    if (_isUniqueEvent)
      this.setState({ uniqueEventCode: _uniqueEventCode });
  }

  //2020.12.03
  SetIsEvent = async (_isEvent, _eventCode, _isReg = false) => {

    //2021.08.25
    await DelayUntil(() => Events.length > 0);

    //2020.12.04
    let _centerName = '';
    let _centerDisplayName = '';  //2021.03.18
    let _actualEventName = '';

    // switch (_eventName) {
    //   default: _centerName = 'SIM'; _actualEventName = 'SPOT 2020'; break;
    //   case 'spot2020': _centerName = 'SIM'; _actualEventName = 'SPOT 2020'; break;
    //   case 'spot2021': _centerName = 'SIM'; _actualEventName = 'SPOT 2021'; break;

    //   case 'abacus-challenge': _centerName = 'Smart Newton'; _actualEventName = 'Abacus Challenge'; break;



    // }

    // let _eventModal = Events.find(x => x.event === _eventName);
    // let _eventModal = Events.filter(x => x.event === _eventName);
    let _eventModal = null;
    for (let i = 0; i < Events.length; i++) {
      if (Events[i].EventCode === _eventCode) {
        _eventModal = Events[i];
        break;
      }
    }
    if (_eventModal !== null) {
      _centerName = _eventModal.Organizer;
      _centerDisplayName = _eventModal.OrganizerDisplayName; //2021.03.18
      _actualEventName = _eventModal.EventName;
    }
    else {
      _isEvent = false;
    }

    this.setState({
      isEventRegistration: _isReg, //_isEvent,
      eventName: _actualEventName,
      centerName: _centerName,    //2020.12.04
      eventCode: _eventCode,      //2021.01.08
      eventModal: _eventModal,    //2021.02.10
      centerDisplayName: _centerDisplayName,  //2021.03.18
    });
  }

  Set_PolicyTncVersion_PublishedDate = (_value) => {
    this.setState({
      policyTncVersion_PublishedDate: _value,
    });
  }

  //2021.02.01
  SetScreen = (screen) => {
    this.setState({
      currentScreen: screen,
    });
  }

  //=== 2021.04.08 - custom right-click popup menu === start
  _handleContextMenu = (event) => {
    // event.preventDefault();
    // this.setState({ visible: true });

    // let clickX = event.clientX;
    // let clickY = event.clientY;
    // let screenW = window.innerWidth;
    // let screenH = window.innerHeight;
    // let rootW = this.root.offsetWidth;
    // let rootH = this.root.offsetHeight;

    // let right = (screenW - clickX) > rootW;
    // let left = !right;
    // let top = (screenH - clickY) > rootH;
    // let bottom = !top;

    // if (right) {
    //   this.root.style.left = `${clickX + 5}px`;
    // }
    // if (left) {
    //   this.root.style.left = `${clickX - rootW - 5}px`;
    // }
    // if (top) {
    //   this.root.style.top = `${clickY + 5}px`;
    // }
    // if (bottom) {
    //   this.root.style.top = `${clickY - rootH - 5}px`;
    // }

    //simplified
    if (!this.state.isDevMode) {
      if (this.state.currentScreen === Screen.LoginScreen) //2023.07.25 - let login page can right-click.
        return null;

      event.preventDefault();
      let action = isMobile ? 'Long-Press' : 'Right-Click';
      this.SetAlert(action + ' is Not Allowed', 'Thank you for using <b>iKEY Live Quiz</b>.<br /><br />But sorry to inform you that <u>' + action + ' is not allowed</u>.');
    }
  };
  // _handleClick = (event) => {
  //   let { visible } = this.state;
  //   let wasOutside = !(event.target.contains === this.root);
  //   if (wasOutside && visible) this.setState({ visible: false, });
  // };
  // _handleScroll = () => {
  //   let { visible } = this.state;
  //   if (visible) this.setState({ visible: false, });
  // };
  _keyupHandler = (e) => {
    // console.log(e.keyCode + ' | ' + e.ctrlKey);
    // e.preventDefault();

    if (this.state.currentScreen === Screen.LoginScreen) //2023.07.25 - let login page can right-click.
      return null;

    //ctrl + s
    if (e.keyCode === 83 && e.ctrlKey) {
      e.preventDefault();
      this.SetAlert('Save is Not Allowed', 'Thank you for using <b>iKEY Live Quiz</b>.<br /><br />But sorry to inform you that <u>this webpage is not allowed to save</b>.');
    }
    //ctrl + p
    if (e.keyCode === 80 && e.ctrlKey) {
      e.preventDefault();
      this.SetAlert('Print is Not Allowed', 'Thank you for using <b>iKEY Live Quiz</b>.<br /><br />But sorry to inform you that <u>this webpage is not allowed to print</b>.');
    }
    //print screen
    else if (e.keyCode === 44) {
      e.preventDefault();
      this.SetAlert('Print Screen is Not Allowed', 'Thank you for using <b>iKEY Live Quiz</b>.<br /><br />But sorry to inform you that <u>Print Screen is not allowed</b>.');
    }
  }
  //=== 2021.04.08 - custom right-click popup menu === end


  //=== Alert === starts.

  //2021.04.02
  SetLoading = (title, content, showTitle = true, extraComponent = null) => {
    this.alert.SetLoading(String(title), String(content), showTitle, extraComponent);
  }

  SetAlert = (title, content, showTitle = true, extraComponent = null) => {
    // this.alert.current.SetAlert(String(title), String(content));
    this.alert.SetAlert(String(title), content, showTitle, extraComponent);
    // this.alert.SetAlert(String(title), content);
  }

  //2021.04.23
  SetAlertWithComponent = (title, content, showTitle = true) => {
    this.alert.SetAlertWithComponent(title, content, showTitle);
  }

  //2020.11.30
  SetAlertConfirm = (title, content, feedbackType, showYes, showNo, showConfirm, showCancel, showClose) => {
    // this.alert.current.SetAlert(String(title), String(content));
    this.alert.SetAlertConfirm(String(title), String(content), feedbackType, showYes, showNo, showConfirm, showCancel, showClose);
  }

  //2020.11.30
  Callback_AlertConfirm = async (btnType, feedbackType = undefined) => {
    if (feedbackType !== undefined) {
      if (feedbackType === Feedback.InternetDown) {
        //re-check again for current Internet connection status.
        //popup Alert again if still disconnected.

        // this.CheckLastUpdate();
        // alert(String(btnType) + "\n" + String(feedbackType));

        // this.CheckInternetReachability();
      }
      else {
        // alert(this.state.currentScreen);
        switch (btnType) {
          case Btn.None:
            // if (this.state.currentScreen === Screen.LoginScreen)
            //   this.LoginScreen_Ref.current.SetSelectedEvent([]);
            break;
          case Btn.Confirm:
            if (this.state.currentScreen === Screen.LoginScreen) {
              // if (useDefaultEvents) {
              //   await Delay(2000);
              //   this.LoginScreen_Ref.current.GotoEventSignUp();
              // }
              // else {

              // //2022.06.16
              // const regData = localStorage.getItem('RgistrationDataViaLoginEventList');
              // const regDataViaLogin = regData === null ? null : JSON.parse(regData);
              // SetContext('EventCode', regDataViaLogin.EventCode);
              // SetContext('AuthorId', regDataViaLogin.AuthorId);
              // SetContext('CenterUserId', regDataViaLogin.CenterUserId);

              this.LoginScreen_Ref.current.GotoEventSignUp();
              // }
            }
            else if (this.state.currentScreen === Screen.SignUpScreen) {
              this.SignUpScreen_Ref.current.GotoLoginPage();
            }
            else if (this.state.currentScreen === Screen.QuizLive) {
              this.QuizLive_Ref.current.GotoHome();
            }
            break;
          case Btn.Yes:
            break;
          case Btn.No:
            break;
          default:
            break;
        }
      }
    }
  }

  // //2022.06.16
  // SetRegistrationSettingsByEvent = (eventCode, centerUserId, authorId) => {
  //   const regSettings = localStorage.getItem()
  //   this.SetEventInfo(eventCode, centerUserId, authorId);
  // }

  //2020.11.30
  ShowInternetNotAvailableAlert = () => {
    this.SetAlert(
      Locale("label-internet-not-avai", this.props.Locale),
      Locale("text-internet-not-avai", this.props.Locale)
    );
  }

  SetAlertWithProgressBar = (title, content, showBar) => {
    // this.alert.current.SetAlertWithProgressBar(String(title), String(content), CheckBoolean(showBar));
    this.alert.SetAlertWithProgressBar(String(title), String(content), CheckBoolean(showBar));
  }

  CloseAlert = () => {
    // this.alert.current.CloseAlert();
    this.alert.CloseAlert();
  }
  //=== Alert === ends.

  SetTimeLeftText = (_timeLeftText) => {
    this.setState({
      timeLeftText: _timeLeftText,
    });
  }

  SetProfile = (profileData) => {
    this.setState({
      profile: profileData,
    });
  }

  SetLog = async (msg = "") => {
    // let date = new Date();
    // let date = new Date(_date);
    // let year = Number(date.getFullYear());
    // let month = Number(date.getMonth() + 1);
    // let day = Number(date.getDate());
    // let hourTxt = Number(date.getUTCHours()) > 9 ? String(date.getUTCHours()) : '0' + String(date.getUTCHours());
    // let minuteTxt = Number(date.getUTCMinutes()) > 9 ? String(date.getUTCMinutes()) : '0' + String(date.getUTCMinutes());
    // let secondTxt = Number(date.getUTCSeconds()) > 9 ? String(date.getUTCSeconds()) : '0' + String(date.getUTCSeconds());
    // let time = hourTxt + minuteTxt + secondTxt;

    const userUid = this.state.user !== null ? String(this.state.user.uid) : this.state.preActiveUserUid;

    //2022.08.23
    const datePath = moment.utc().format('YYYY/M/D/HHmmss');

    //2021.09.13 - add retry.
    let retryAgain = false;
    let done = false;
    // let async_action = async () => await dbLogs.ref(userUid + '/' + year + '/' + month + '/' + day + '/' + time)
    let async_action = async () => {
      await dbLogs
        .ref(userUid + "/" + datePath)
        .set(String(msg))
        .then(() => {
          done = true;
        })
        .catch((error) => {
          retryAgain = true;
          done = true;
          if (this.state.isDevMode)
            console.log(error);
        });
      await DelayUntil(() => done === true);
    }
    do {
      retryAgain = false;
      done = false;
      await async_action();
      // await DelayUntil(() => done === true);
    } while (retryAgain);
  }

  //2023.10.30
  SetLiveQuizLog = async (msg = '', roomId = '') => {
    if (this.state.user === null)
      return null;

    const utcNow = moment.utc();
    const datePath = utcNow.format('YYYYMMDDHHmmss');
    const message = String(msg) + ' | ' + utcNow.toDate();

    let retryAgain = false;
    // let done = false;
    do {
      retryAgain = false;
      let done = false;
      let retry = false;
      await dbLiveQuizLogs
        .ref(String(this.state.user.uid) + '/' + roomId + '/' + datePath)
        .set(message)
        .then(() => {
          done = true;
        })
        .catch((error) => {
          retry = true;
          done = true;
          if (this.state.isDevMode)
            console.log('SetLiveQuizLog (Error)', error);
        });
      await DelayUntil(() => done === true);
      retryAgain = retry;
    } while (retryAgain);
  }

  //2023.10.30
  SetUserLog = async (msg = '') => {
    if (this.state.user === null)
      return null;

    const datePath = moment.utc().format('YYYY/M/D/HHmmss');

    let retryAgain = false;
    do {
      retryAgain = false;
      let done = false;
      let retry = false;
      await dbUserLogs
        .ref(String(this.state.user.uid) + "/" + datePath)
        .set(String(msg))
        .then(() => {
          done = true;
        })
        .catch((error) => {
          retry = true;
          done = true;
          if (this.state.isDevMode)
            console.log('SetUserLog (Error)', error);
        });
      await DelayUntil(() => done === true);
      retryAgain = retry;
    } while (retryAgain);
  }

  SetErrorLog = async (_date, msg) => {
    // let date = new Date();
    let date = new Date(_date);
    let year = Number(date.getFullYear());
    let month = Number(date.getMonth() + 1);
    let day = Number(date.getDate());
    let hourTxt = Number(date.getUTCHours()) > 9 ? String(date.getUTCHours()) : '0' + String(date.getUTCHours());
    let minuteTxt = Number(date.getUTCMinutes()) > 9 ? String(date.getUTCMinutes()) : '0' + String(date.getUTCMinutes());
    let secondTxt = Number(date.getUTCSeconds()) > 9 ? String(date.getUTCSeconds()) : '0' + String(date.getUTCSeconds());
    let time = hourTxt + minuteTxt + secondTxt;

    let userUid = this.state.user !== null ? String(this.state.user.uid) : this.state.preActiveUserUid;

    //2021.09.13 - add retry.
    let retryAgain = false;
    let done = false;
    let async_action = async () => {
      await dbErrorLogs.ref(userUid + '/' + year + '/' + month + '/' + day + '/' + time)
        .set(String(msg))
        .then(() => {
          done = true;
        })
        .catch(() => {
          retryAgain = true;
          done = true;
        });
      await DelayUntil(() => done === true);
    }

    do {
      retryAgain = false;
      done = false;
      await async_action();
      // await DelayUntil(() => done === true);
    } while (retryAgain);
  }

  resetUserLogin = (email, password) => {
    this.setState({
      user: null,
      email: String(email),
      password: String(password),
      isNewUser: false,
    }, () => {
      this.CloseAlert();
    });
  }

  setEmailPassword = (email, password) => {
    this.setState({
      email: String(email),
      password: String(password),
    });
  }

  setEmailOnly = (email) => {
    this.setState({
      email: String(email),
    });
  }

  //normal user login.
  authHandler = async (authData, email = '', password = '', customToken = '') => {
    // console.log('authHandler', JSON.stringify(authData));
    this.setState({
      Credential: JSON.parse(JSON.stringify(authData)),
      // Credential: { ...authData },
    },
      () => {
        //Uid of "ikey.quiz.editor@gmail.com"
        // this.SetSuperAdmin(authData.user.email === "ikey.quiz.editor@gmail.com");
        this.SetSuperAdmin(authData.user.uid === "uj4Zk0dQSyNfIIJBqVPfne8y5z32" || authData.user.uid === "460db00a-1203-4562-840c-18de74d85355");
        this.SetAuthor(false);
        this.setUser(authData.user);
        this.CloseAlert();
        this.setState({ LoginState: { Success: true, ErrorCode: '', Message: '' }, });
        if (this.state.isDevMode) {
          // console.log(JSON.stringify(authData.user));
          console.log('Credential:\n' + JSON.stringify(authData));
        }
        this.setState({ isLoginDone: true, });    //2023.10.31
      }
    );
    //2024.07.10 - no more needed to callback for updating credentials.
    return null;

    // //2023.09.28
    // let email = CheckNullValue(authData.user.email) !== null ? CheckStringEmpty(authData.user.email) : (CheckNullValue(email) !== null ? CheckStringEmpty(email) : '');
    // if (CheckNullValue(email) === null)
    //   email = CheckStringEmpty(this.state.email);

    // //2022.08.12 - sync login status
    // const postData = {
    //   // Uid: String(authData.user.uid),
    //   // Email: authData.user.email !== null ? String(authData.user.email).trim() : (email !== null && email !== '' ? String(email).trim() : ''),
    //   // Password: password !== null && password !== '' ? String(password).trim() : '',
    //   // RefreshToken: CheckNullValue(authData.user.stsTokenManager.refreshToken) === null ? '' : String(authData.user.stsTokenManager.refreshToken),
    //   // AccessToken: CheckNullValue(authData.user.stsTokenManager.accessToken) === null ? '' : String(authData.user.stsTokenManager.accessToken),
    //   Uid: CheckObjectNullValue(this.state.Credential, 'user') === null ? '' : CheckObjectStringEmpty(this.state.Credential.user, 'uid', ''),
    //   Email: CheckStringEmpty(email),
    //   Password: CheckStringEmpty(password),
    //   RefreshToken: CheckObjectNullValue(this.state.Credential, 'user') === null || CheckObjectNullValue(this.state.Credential.user, 'stsTokenManager') === null ? '' : CheckObjectStringEmpty(this.state.Credential.user.stsTokenManager, 'refreshToken', ''),
    //   AccessToken: CheckObjectNullValue(this.state.Credential, 'user') === null || CheckObjectNullValue(this.state.Credential.user, 'stsTokenManager') === null ? '' : CheckObjectStringEmpty(this.state.Credential.user.stsTokenManager, 'accessToken', ''),
    //   // RefreshToken: CheckObjectStringEmpty(this.state.Credential.user.stsTokenManager, 'refreshToken'),
    //   // AccessToken: CheckObjectStringEmpty(this.state.Credential.user.stsTokenManager, 'accessToken'),
    //   CustomToken: CheckStringEmpty(customToken),  //2024.07.05
    // };
    // if (this.state.isDevMode) {
    //   console.log('authHandler / authData =\n', JSON.stringify(authData));
    //   console.log('authHandler / postData = \n', JSON.stringify(postData));
    // }
    // await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/User/Student/Credential/Update',
    //   // 'Api/LearningCentre/User/Student/Credential/Update'  //current 2024.07.05
    //   // 'Api/LearningCentre/User/Login/Status/Update'  //old
    //   {
    //     method: 'POST',
    //     headers: {
    //       'Accept': 'application/json',
    //       'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify(postData),
    //   })
    //   .then(() => {

    //   })
    //   .catch(error => {
    //     if (this.state.isDevMode)
    //       console.log('api sync login status error =\n' + error.message);
    //   });
  }

  SetLoginState = (state) => {
    this.setState({ LoginState: state });
  }

  //2022.08.23
  SetUserDataOnly = (user) => {
    this.setState({
      user: user,
    });
  }

  setUser = (user) => {
    let _user = { ...user };
    _user.email = this.state.email;
    this.setState({
      user: _user,
      preActiveUserUid: user !== null ? String(user.uid) : this.state.preActiveUserUid,
    }, async () => {
      // console.error("User data loaded : " + JSON.stringify(this.state.user));

      //Send Login info to Firebase.
      let nowDT = new Date();
      let osInfo = osName + ' ' + osVersion;
      let browserInfo = browserName + ' ' + fullBrowserVersion;
      // "Login at " + "2020-10-01 10:20:23 GMT+08:00" + " | App: 2.2.3.2 (72) | OS: Android OS 10 / API-29 (QKQ1.190825.002/V12.0.1.0.QFDCNXM)"

      let preStatText = "";
      if (this.state.isNewUser) {
        preStatText = "Account Created";
      }
      else {
        if (user !== null)
          preStatText = "Login";
        else
          preStatText = "Logout";
      }

      let msg = preStatText + " at " + String(nowDT) + " | Web: 1.0.1 (1)  | OS: " + osInfo + " | Platform: " + browserInfo + " | PLU: " + this.state.LastUpdateDate;

      // this.postLogsPath(nowDT).set({ msg });
      await this.SetLog(msg);
      await this.SetUserLog(msg);  //2023.10.30
      // alert(this.postLogsPath(nowDT).toString());

      //2021.12.28
      if (user !== null)
        localStorage.setItem('Login_DT', moment().format('YYYY-MM-DD HH:mm:ss'));
    });
  }

  setRoomCodeId = (code, id, date = '') => {
    this.setState({
      quizRoomCode: code,
      quizRoomId: id,
      quizRoomDate: date,
    });
  }

  newUserLogin = async () => {
    this.setState({ isNewUser: true, });
  }

  //Login
  handleLogin = async (email, password, rememberMe = false, showErrorMessage = true) => {
    this.setEmailPassword(email, password);
    this.setState({ isLoginDone: false, });   //2023.10.31

    if (this.state.LoginAsAuthor === false) {
      //Normal Login (Firebase).
      let setPersistenceDone = false;
      await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
        .then(() => {
          setPersistenceDone = true;
        });
      //#region old codes
      // .then(async () => {
      //   // Existing and future Auth states are now persisted in the current
      //   // session only. Closing the window would clear any existing state even
      //   // if a user forgets to sign out.
      //   // ...
      //   // New sign-in will be persisted with session persistence.
      //   // return firebase.auth().signInWithEmailAndPassword(email, password);

      //   await firebase
      //     .auth()
      //     .signInWithEmailAndPassword(email, password)
      //     // .then((data)=>{
      //     //     alert("login done : " + JSON.stringify(data.user));
      //     // })            
      //     .then(async (data) => this.authHandler(data))
      //     // .then(() => {
      //     //   return <Redirect to="/home" />;
      //     // })
      //     .catch((error) => {
      //       this.handleSetError("Error signing in with password and email !", error);
      //       // alert("Error signing in with password and email !" + "\n\n [" + error + "]");
      //       this.CloseAlert();
      //     });
      // })
      // .catch((error) => {
      //   this.handleSetError("Error signing in.", "Please try again.\n\n" + error);
      //   this.CloseAlert();
      // });
      //#endregion

      //2020.12.10
      if (setPersistenceDone) {

        await this.LoginStudentByCustomToken(email, password, rememberMe);
        return null;

        await firebase
          .auth()
          .signInWithEmailAndPassword(email, password)
          // .then((data)=>{
          //     alert("login done : " + JSON.stringify(data.user));
          // })            
          .then(async (userCredential) => {
            await this.authHandler(userCredential, email, password);
          })
          .catch((error) => {
            this.setState({ isLoginDone: true, });    //2023.10.31
            if (this.state.isDevMode)
              console.log(error);
            // this.handleSetError("Error signing in with password and email !", "Please try again.\n\n" + error);

            //2021.04.14
            let title = Locale("error", this.state.Locale);
            let msg = '';
            if (error.code === 'auth/user-not-found') {
              msg = Locale("notice-login-user-not-found", this.state.Locale);
              if (showErrorMessage)
                this.SetAlert(title, msg);
            }
            else if (error.code === 'auth/wrong-password') {
              msg = Locale("notice-login-wrong-password", this.state.Locale);
              if (showErrorMessage)
                this.SetAlert(title, msg);
            }
            else {
              msg = error.message;
              if (showErrorMessage)
                this.SetAlert(title, error.message);
            }

            // return false;
            this.SetLoginState({ Success: false, ErrorCode: error.code, Message: msg });
            // this.CloseAlert();
          });
      }
    }
    else {
      //Login As Admin.
      await this.LoginUserByCustomToken(email, password, rememberMe);
    }
  }

  //2024.07.05
  LoginStudentByCustomToken = async (email = '', password = '', rememberMe = true) => {
    let success = false;
    let errorMessage = '';
    // let response = null;
    let customLoginResponse = null;
    let customLoginToken = '';

    const loginModal = JSON.stringify({
      Email: CheckStringEmpty(email),
      Password: CheckStringEmpty(password),
      RememberMe: CheckBoolean(rememberMe),
      Identity: 5,
    });
    if (this.state.isDevMode)
      console.log('LoginStudentByCustomToken (modal) =\n' + loginModal);

    await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/User/Student/Login',
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: loginModal,
      })
      .then(res => res.json())
      .then((data) => {
        if (this.state.isDevMode)
          console.log('api login (response) =\n' + JSON.stringify(data));
        success = CheckObjectBoolean(data, 'success');
        if (success)
          customLoginResponse = JSON.parse(JSON.stringify(data.data));
        else
          errorMessage = CheckObjectStringEmpty(data, 'message');
      })
      .catch(error => {
        errorMessage = error.message;
        if (this.state.isDevMode)
          console.log('api login (error) =\n' + error.message);
      });

    //2024.07.10 - custom login to get latest access token & refresh token handle in api.
    // if (response !== null) {
    //   const { Uid, Email, Token } = response;
    //   customLoginToken = Token;
    //   if (CheckNullValue(customLoginToken) === null) {
    //     errorMessage = 'invalid token.';
    //   }
    //   else {
    //     await firebase
    //       .auth()
    //       .signInWithCustomToken(customLoginToken)
    //       .then((userCredential) => {
    //         customLoginResponse = JSON.parse(JSON.stringify(userCredential));
    //         success = true;
    //       })
    //       .catch((error) => {
    //         this.setState({ isLoginDone: true, });
    //         const title = Locale("error", this.state.Locale);
    //         switch (error.code) {
    //           case 'INVALID_CUSTOM_TOKEN': this.SetAlert(title, Locale("notice-login-user-invalid-token", this.state.Locale)); break;
    //           case 'CREDENTIAL_MISMATCH': this.SetAlert(title, Locale("notice-login-user-credential-mismatch", this.state.Locale)); break;
    //           default: this.SetAlert(title, error.message); break;
    //         }
    //         this.SetLoginState({ Success: false, ErrorCode: error.code, Message: errorMessage });
    //       });
    //   }
    // }

    if (success) {
      // console.log('customLoginResponse', JSON.stringify(customLoginResponse));
      await this.authHandler({
        user: {
          uid: customLoginResponse.uid,
          email: email,
        }
      }, email, password, customLoginToken);
    }
    else {
      // this.SetAlert('Error : Login failed',
      //   "Failed to login with current email and password.<br />Please try again."
      //   + (errorMessage === '' ? '' : "<br /><br />Error:<br />" + errorMessage));

      this.SetAlert('Error : Login failed',
        <>
          <div dangerouslySetInnerHTML={{
            __html: "Failed to login with current email and password.<br />Please try again."
              + (errorMessage === '' ? '' : "<br /><br />Error:<br />" + errorMessage)
          }}></div><button type='button' className='btn-link'
            onClick={() => this.LoginScreen_Ref.current.GotoSignUp()}
          >- click here to register new account -</button></>);
    }
  }

  //=== Login logics from Learning Centre === start ===//
  LoginUserByCustomToken = async (email, password, rememberMe) => {

    let isLoginSuccess = false;
    let errorMessage = '';
    let loginResponse = null;
    let apiAuthResponse = null;

    let loginModal = JSON.stringify({
      Email: String(email),
      Password: String(password),
      RememberMe: CheckBoolean(rememberMe),
    });
    // console.log(loginModal);

    await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/User/Author/Login',    //'Api/LearningCentre/Account/Login',
      {
        method: 'POST',                             // *GET, POST, PUT, DELETE, etc.
        // mode: 'cors',                            // no-cors, *cors, same-origin
        // cache: 'no-cache',                          // *default, no-cache, reload, force-cache, only-if-cached
        // credentials: 'omit',                 // include, *same-origin, omit
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        // redirect: 'follow',                         // manual, *follow, error
        // referrerPolicy: 'no-referrer',              // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: loginModal, // body data type must match "Content-Type" header
      })
      .then(res => res.json()
        // , error => {
        //   console.log('error (json) =\n' + error.message);
        // }
      )
      .then((data) => {
        // console.log('api login response =\n' + JSON.stringify(data));
        // apiAuthResponse = data.result;
        apiAuthResponse = data;
        // if (data.success)
        //   apiAuthResponse = data;
        // else
        //   errorMessage += '<br /> | api login response. ' + data.message;
      }
        // , error => {
        //   console.log('error (data) =\n' + error.message);
        // }
      )
      .catch(error => {
        errorMessage += '<br /> | api login failed. | ' + error.message;  // + '<br />' + loginModal;
        if (this.state.isDevMode)
          console.log('api login error =\n' + error.message);
      });

    if (this.state.isDevMode)
      console.log('api login response =\n' + JSON.stringify(apiAuthResponse));

    if (apiAuthResponse !== null) {
      // alert(JSON.stringify(response));
      if (CheckObjectBoolean(apiAuthResponse, 'success')) {
        // alert(
        //     response.code + "\n" + String(UserLoginResults.UserNotExist)
        //     + "\n" + response.code === String(UserLoginResults.UserNotExist)
        // );
        const isUserNotExist = apiAuthResponse.code === String(UserLoginResults.UserNotExist);
        if (isUserNotExist) {
          //Primarily for testing only.
          //User is NOT FOUND in Base, proceed to login normally to Firebase. 
          // let result = await this.Simple_LoginUserByEmailPassword(email, password);
          // if (result.isLoginSuccess) {
          //     isLoginSuccess = result.isLoginSuccess;
          //     user = result.user;
          //     response = null;
          // }
          // alert("isUserNotExist : " + isUserNotExist + "\n\n" + JSON.stringify(apiAuthResponse));
          errorMessage += "<br /> | isUserNotExist: " + isUserNotExist + "<br />" + JSON.stringify(apiAuthResponse);
        }
        else {
          const modal = JSON.parse(CheckObjectStringEmpty(apiAuthResponse, 'message'));
          // console.log(apiAuthResponse.message);
          // console.log(JSON.stringify(modal));

          // alert(modal.FirebaseToken);
          // navigator.clipboard.writeText(modal.FirebaseToken);
          // alert(JSON.stringify(modal));
          //User is found in Base, and proceed to login to Firebase via Custom Token.
          const proceedWithFirebaseToken = modal.hasOwnProperty('FirebaseToken') ? String(modal.FirebaseToken).length > 0 : false;
          // alert(proceedWithFirebaseToken);
          if (proceedWithFirebaseToken) {
            // let setPersistenceDone = await this.SetPersistence();
            // if (setPersistenceDone) {
            await firebase
              .auth()
              .signInWithCustomToken(modal.FirebaseToken)
              .then((userCredential) => {
                loginResponse = userCredential;
                isLoginSuccess = true;
              })
              .catch((error) => {
                const title = Locale("error", this.state.Locale);
                if (error.code === 'auth/user-not-found')
                  this.SetAlert(title, Locale("notice-login-user-not-found", this.state.Locale));
                else if (error.code === 'auth/wrong-password')
                  this.SetAlert(title, Locale("notice-login-wrong-password", this.state.Locale));
                else
                  this.SetAlert(title, error.message);
              });
            // }
          }
          else {
            errorMessage += '<br /> | Api Login Error.<br />Invalid Token or Fetch Response.<br />' + JSON.stringify(apiAuthResponse);
          }
        }
      }
    }

    if (isLoginSuccess) {
      await this.PopulateUserIdentity(loginResponse, apiAuthResponse);
    }
    else {
      setTimeout(() => {
        this.SetAlert('Error : Login failed', "Failed to login with current email and password.<br />Please try again.<br /><br />Error:<br />" + errorMessage);
      }, 1000);
    }
    return isLoginSuccess;
  }
  //from Custom Login.
  PopulateUserIdentity = async (userCredential, apiAuthResponse) => {

    userCredential = JSON.parse(JSON.stringify(userCredential));
    // userCredential = { ...userCredential };
    this.setState({ Credential: userCredential, });
    if (this.state.isDevMode) {
      console.log('Credential (Firebase):\n' + JSON.stringify(userCredential));
      // console.log('apiAuthResponse (Custom):\n' + JSON.stringify(apiAuthResponse));
    }

    //Init user modal.
    // loginResponse = JSON.parse(JSON.stringify(loginResponse));
    let user = userCredential.user;

    apiAuthResponse = JSON.parse(CheckObjectStringEmpty(apiAuthResponse, 'message'));
    // navigator.clipboard.writeText(JSON.stringify(apiAuthResponse));

    //Populate user modal.
    // if (response !== null) {
    //Login via API with Custom Token.
    let modal = {
      AuthorId: CheckObjectNumber(apiAuthResponse, 'AuthorId'),
      UserId: CheckObjectNumber(apiAuthResponse, 'UserId'),
      CenterUserId: CheckObjectNumber(apiAuthResponse, 'CenterUserId'),
      AuthorRoleId: CheckObjectNumber(apiAuthResponse, 'AuthorRoleId'),
      IsActive: CheckObjectBoolean(apiAuthResponse, 'IsActive'),
      FirebaseCustomToken: CheckObjectStringEmpty(apiAuthResponse, 'FirebaseToken'),

      FirebaseUserId: CheckObjectStringEmpty(user, 'uid'),
      FirebaseAccessToken: CheckObjectNullValue(user.stsTokenManager, 'accessToken'),
      FirebaseRefreshToken: CheckObjectNullValue(user.stsTokenManager, 'refreshToken'),
      FirebaseLastLoginTime: CheckObjectNumber(user, 'lastLoginAt'),                        //epoch timestamp with ms, login time.
      FirebaseExpirationTime: Number(user.stsTokenManager.expirationTime),    //epoch timestamp with ms, est. 1hr frm login.
    };

    // navigator.clipboard.writeText(JSON.stringify(modal));
    if (this.state.isDevMode) {
      console.log('modal:\n' + JSON.stringify(modal));
      console.log('apiAuthResponse:\n' + JSON.stringify(apiAuthResponse));
    }

    //Update data on iKEY Base.
    await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/User/Author/Update/AuthorCredential',
      {
        method: 'POST',                                // *GET, POST, PUT, DELETE, etc.
        // mode: 'no-cors',                               // no-cors, *cors, same-origin
        // cache: 'no-cache',                          // *default, no-cache, reload, force-cache, only-if-cached
        // credentials: 'same-origin',                 // include, *same-origin, omit
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        // redirect: 'follow',                         // manual, *follow, error
        // referrerPolicy: 'no-referrer',              // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(modal),
      })
      .then(response => response.json())
      .then(data => {
        if (!data.success) {
          console.log('Cred Update Failed', 'api update user cre resp.<br /><br />' + data.message);
        }
      })
      .catch(error => {
        console.log('Cred Update Failed (Error)', 'api update user cre catch.<br /><br />' + error.message);
      });

    //2022.06.27
    user.IsOrganizer = CheckObjectBoolean(apiAuthResponse, 'IsOrganizer');
    user.OrganizerInfoList = CheckObjectNullValue(apiAuthResponse, 'OrganizerInfoList') === null ? [] : apiAuthResponse.OrganizerInfoList;   //null or list of organizer info.

    //2023.09.07
    modal.CustomPermissions = CheckObjectNullValue(apiAuthResponse, 'CustomPermissions') === null ? null : JSON.parse(CheckObjectStringEmpty(apiAuthResponse, 'CustomPermissions'));
    modal.IsAdmin = CheckObjectBoolean(apiAuthResponse, 'IsAdmin');
    modal.IsViewer = CheckObjectBoolean(apiAuthResponse, 'IsViewer');

    //2023.11.30
    modal.ForceResetPassword = CheckObjectBoolean(apiAuthResponse, 'ForceResetPassword');

    //2023.11.28
    // console.log('modal.CustomPermissions (before)', JSON.stringify(modal.CustomPermissions));
    if (modal.CustomPermissions !== null) {
      if (Array.isArray(modal.CustomPermissions)) {
        let isFound = false;
        for (let ac = 0; ac < modal.CustomPermissions.length; ac++) {
          if (modal.CustomPermissions[ac].hasOwnProperty('manageCustomGroup')) {
            isFound = true;
            break;
          }
        }
        if (isFound === false) {
          modal.CustomPermissions.push({ manageCustomGroup: DefaultCustomPermissionModel_Allowed });
        }
      }
    }
    // console.log('modal.CustomPermissions (after)', JSON.stringify(modal.CustomPermissions));

    //save user modal.
    await this.SetUserIdentity(user, modal);
  }
  SetUserIdentity = async (user, modal) => {
    user.AuthorId = modal.AuthorId;
    user.UserId = modal.UserId;
    user.CenterUserId = modal.CenterUserId;
    user.AuthorRoleId = modal.AuthorRoleId;
    user.IsActive = modal.IsActive;
    user.FirebaseCustomToken = CheckObjectStringEmpty(modal, 'FirebaseCustomToken');
    user.FirebaseUserId = CheckObjectStringEmpty(modal, 'FirebaseUserId');
    user.FirebaseAccessToken = CheckObjectStringEmpty(modal, 'FirebaseAccessToken');
    user.FirebaseRefreshToken = CheckObjectStringEmpty(modal, 'FirebaseRefreshToken');
    user.FirebaseLastLoginTime = modal.FirebaseLastLoginTime;      //epoch timestamp with ms, login time.
    user.FirebaseExpirationTime = modal.FirebaseExpirationTime;    //epoch timestamp with ms, est. 1hr frm login.

    //2023.09.07
    user.CustomPermissions = modal.CustomPermissions;
    user.IsAdmin = modal.IsAdmin;
    user.IsViewer = modal.IsViewer;

    //2023.11.30
    user.ForceResetPassword = modal.ForceResetPassword;

    //admin or author related.
    let adminRoles = [1, 9];    //1 = "Administrators", 9 = "iKey Administrator"
    let findAdminIndex = adminRoles.findIndex(x => x === CheckObjectNumber(user, 'AuthorRoleId'));
    this.SetSuperAdmin(findAdminIndex > -1 || CheckObjectStringEmpty(user, 'FirebaseUserId') === '460db00a-1203-4562-840c-18de74d85355' || CheckObjectStringEmpty(user, 'UserId') === '28468588');
    // this.SetSuperAdmin(findAdminIndex > -1 || user.IsAdmin);
    // if (String(user.FirebaseUserId) === '460db00a-1203-4562-840c-18de74d85355') {
    //   //ikey.quiz.editor@gmail.com ==> login using admin password.
    //   this.SetSuperAdmin(true);
    // }

    // Id	Name
    // 1*	  Administrators
    // 2	  Registered
    // 3	  Guests
    // 4*	  Center
    // 5*	  Teacher
    // 6	  Parent
    // 7*	  Headquarter
    // 8*	  Center Staff
    // 9*	  iKey Administrator
    // 10	  Dragonfly Headquarter
    // 11*	Learning Centre Author
    // 12	  Toy Eight
    let authorRoles = [1, 4, 5, 7, 8, 9, 11];
    let findAuthorIndex = authorRoles.findIndex(x => x === CheckObjectNumber(user, 'AuthorRoleId'));
    this.SetAuthor(findAuthorIndex > -1);

    //2023.11.17
    if (this.state.isSuperAdmin) {
      user.CustomPermissions = AdminCustomPermissions;
    }

    // //2021.12.10
    // if (this.state.isAuthor === false) {
    //   if (this.state.isSuperAdmin)
    //     this.SetAuthor(true);
    // }

    this.setUser(user);
    this.CloseAlert();
    if (this.state.isDevMode)
      console.log('custom login completed.');
    await Delay(500);
  }
  //=== Login logics from Learning Centre === end ===//

  //Logout
  handleLogout = async () => {
    this.SetIsPolicyTncStateTheLatest(false);
    this.setUser(null);
    await firebase.auth().signOut();
    this.setState({
      user: null,

      //2020.12.10
      isNewUser: false,
      profile: null,
      isSuperAdmin: false,
      errorMessage: '',
      quizRoomCode: '',
      quizRoomId: '',
      historyList: [],
      viewHistoryQuiz: null,
      timeLeftText: '',

    }, () => {
      if (!this.state.isFromParentApp) {
        if (this.state.isDevMode)
          // window.location.reload();
          window.location.href = window.location.href.split('/')[0] + '/' + this.state.OrganizerIdentity;
        else
          window.location.href = "https://livequiz.ikeyedutech.com.my/" + this.state.OrganizerIdentity;
      }
    });
  }

  //Error Message
  handleSetError = (msg, error) => {
    this.setState({
      errorMessage: msg,
    }, () => {
      alert(msg + "\n\n [" + error + "]");
    });
  }

  //==== Quiz History List === starts..
  SetViewHistoryQuiz = (value) => {
    this.setState({
      viewHistoryQuiz: value,
    });
  }

  SetHistoryList = async (list) => {
    this.setState({
      historyList: list,
    }, () => {
      // this.CustomEditHistoryList();
      // alert(this.state.historyList);
    });
  }

  //#region === obsolete function, checked 2023.11.22
  /*
  //For editing history only.
  CustomEditHistoryList = () => {
    if (this.state.historyList.length > 0) {
      let isFound = false;
      let keyId = -1;
      this.state.historyList.map((data, key) => {
        if (data.RoomCode === "43112") {
          isFound = true;
          keyId = key;
          // alert("Found");
        }
        return null;
      });
      // let historyToSave = {
      //   Title: "四年级 华语 Testing (Fill in the Blank)",
      //   RoomCode: "94677",
      //   RoomId: "1602551474496",
      //   Date: moment("Oct 13, 2020 9:11 AM").format("lll"),
      // };
      let historyToSave = {
        Title: "一年级 数学测试",
        RoomCode: "43112",
        RoomId: "1604459316745",
        // Date: moment("Nov 4, 2020 9:11 AM").format("lll"),
        Date: moment("Nov 4, 2020 9:11 AM").format('YYYY-MM-DD HH:mm:ss'),
      };
      //if history found in list. update it.
      if (isFound) {
        // this.state.historyList[keyId].Title = historyToSave.Title;
        // this.state.historyList[keyId].RoomId = historyToSave.RoomId;
        // this.state.historyList[keyId].Date = historyToSave.Date;

        let historyList = this.state.historyList;
        historyList[keyId].Title = historyToSave.Title;
        historyList[keyId].RoomId = historyToSave.RoomId;
        historyList[keyId].Date = historyToSave.Date;

        // if (historyToSave.Date !== this.state.historyList[keyId].Date) {
        //   this.state.historyList[keyId].Date = historyToSave.Date;  //only update time, remain same date.
        // }
        this.setState({
          // historyList: this.state.historyList,
          historyList: historyList,
        }, () => {
          localStorage.setItem("LiveQuizHistoryList_List_" + this.state.user.uid, JSON.stringify(this.state.historyList));
          // localStorage.setItem("LiveQuizHistoryList", JSON.stringify(this.state.historyList));
          // if (this.state.user !== null)
          //   localStorage.setItem("LiveQuizHistoryList_" + this.state.user.uid, JSON.stringify(this.state.historyList));
          // else
          //   localStorage.setItem("LiveQuizHistoryList", JSON.stringify(this.state.historyList));
        });
      }
    }
  }

  UpdateScoreInRoomHistoryList = async (roomModal) => {
    let updateDateTime = moment().format('YYYY-MM-DD HH:mm:ss');
    if (this.state.historyList.length > 0) {
      let isFound = false;
      let keyId = 0;
      this.state.historyList.map((data, key) => {
        if (
          String(data.RoomCode) === String(roomModal.RoomCode)
          && String(data.RoomId) === String(roomModal.RoomId)
        ) {
          isFound = true;
          keyId = key;
          // alert("found");
        }
        return null;
      });

      if (isFound) {
        let _historyList = this.state.historyList;
        _historyList[keyId].Score = roomModal.Score;

        this.setState({
          historyList: _historyList,
        });

        //Update to local history list.
        if (this.state.isHistoryListSyncDone && this.state.isHistoryListExists_Local) {
          localStorage.setItem("LiveQuizHistoryList_List_" + this.state.user.uid, JSON.stringify(this.state.historyList));
          localStorage.setItem("LiveQuizHistoryList_LU_" + this.state.user.uid, updateDateTime);
        }

        //Update to FireStore.
        await this.Process_SingleRecord_UpdateTo_HistoryListOnFireStore(_historyList[keyId]);
        await this.Update_HistoryList_LastUpdate_OnFireStore(updateDateTime);

        // alert(JSON.stringify(_historyList[keyId]));
      }
    }
  }

  SaveRoomInLocalHistoryList = async (historyToSave) => {
    let updateDateTime = moment().format('YYYY-MM-DD HH:mm:ss');

    if (this.state.historyList.length > 0) {
      let isFound = false;
      // let keyId = -1;
      this.state.historyList.map((data, key) => {
        if (String(data.RoomCode) === String(historyToSave.RoomCode)) {
          isFound = true;
          // keyId = key;
        }
        return null;
      });

      let _historyList = this.state.historyList;

      if (!isFound) {

        if (String(historyToSave.Date).toLowerCase().includes('invalid')) {
          historyToSave.Date = moment().format('YYYY-MM-DD HH:mm:ss');
        }

        //if history not found. direct do save.
        _historyList.push(historyToSave);
        // this.state.historyList.push(historyToSave);
        // this.setState({
        //   historyList: this.state.historyList,
        // }, () => {
        //   localStorage.setItem("LiveQuizHistoryList", JSON.stringify(this.state.historyList));
        // });
      }
      // else {
      //   //if history found in list. compare Date & save.
      //   if (historyToSave.Date !== null && historyToSave.Date !== this.state.historyList[keyId].Date) {
      //     let newSaveDate = moment(historyToSave.Date);
      //     let oldSaveDate = moment(this.state.historyList[keyId].Date);

      //     // let historyList = this.state.historyList;

      //     if (newSaveDate.getFullYear === oldSaveDate.getFullYear
      //       && newSaveDate.getMonth === oldSaveDate.getMonth
      //       && newSaveDate.getDate === oldSaveDate.getDate) {
      //       // this.state.historyList[keyId].Date = historyToSave.Date;  //only update time, remain same date.
      //       historyList[keyId].Date = historyToSave.Date;  //only update time, remain same date.
      //     }
      //   }
      //   // this.setState({
      //   //   // historyList: this.state.historyList,
      //   //   historyList: historyList,
      //   // }, () => {
      //   //   localStorage.setItem("LiveQuizHistoryList", JSON.stringify(this.state.historyList));
      //   // });
      // }

      //added 2020.11.05 - reformat the date.
      if (_historyList.length > 0) {
        _historyList.map((data, key) => {
          // return _historyList[key].Date = moment(String(_historyList[key].Date)).format('YYYY-MM-DD HH:mm:ss');

          //2020.12.12
          _historyList[key].Date = moment(String(_historyList[key].Date)).format('YYYY-MM-DD HH:mm:ss');
          // if (String(_historyList[key].Date).toLowerCase().includes('invalid')) {
          //   _historyList[key].Date = moment(Number(_historyList[key].RoomId)).format('YYYY-MM-DD HH:mm:ss');
          //   // _historyList[key].Date = moment().format('YYYY-MM-DD HH:mm:ss');
          // }

          return null;
        });
      }

      this.setState({
        historyList: _historyList,
      }, async () => {
        // localStorage.setItem("LiveQuizHistoryList", JSON.stringify(this.state.historyList));

        // if (historyToSave.Date !== null) {

        // alert(this.state.historyList.length);

        // if (!isFound) {

        //   if (this.state.isHistoryListSyncDone && this.state.isHistoryListExists_Local) {
        //     localStorage.setItem("LiveQuizHistoryList_List_" + this.state.user.uid, JSON.stringify(this.state.historyList));
        //     localStorage.setItem("LiveQuizHistoryList_LU_" + this.state.user.uid, updateDateTime);
        //   }

        //   //Update to FireStore.
        //   await this.Process_SingleRecord_UpdateTo_HistoryListOnFireStore(historyToSave);
        //   await this.Update_HistoryList_LastUpdate_OnFireStore(updateDateTime);
        // }

        // }
      });

      if (!isFound) {

        if (this.state.isHistoryListSyncDone && this.state.isHistoryListExists_Local) {
          localStorage.setItem("LiveQuizHistoryList_List_" + this.state.user.uid, JSON.stringify(this.state.historyList));
          localStorage.setItem("LiveQuizHistoryList_LU_" + this.state.user.uid, updateDateTime);
        }

        //Update to FireStore.
        await this.Process_SingleRecord_UpdateTo_HistoryListOnFireStore(historyToSave);
        await this.Update_HistoryList_LastUpdate_OnFireStore(updateDateTime);
      }
    }
    else {
      // let canUpdateLocalHistoryList = this.state.isHistoryListLoaded;
      // if (this.state.LU_HistoryList_Local !== '' && this.state.LU_HistoryList_FS !== '') {
      //   canUpdateLocalHistoryList = moment(this.state.LU_HistoryList_Local) > moment(this.state.LU_HistoryList_FS);
      // }

      //added 2020.11.05 - temp
      historyToSave.Date = moment(String(historyToSave.Date)).format('YYYY-MM-DD HH:mm:ss');
      if (String(historyToSave.Date).toLowerCase().includes('invalid'))
        historyToSave.Date = moment().format('YYYY-MM-DD HH:mm:ss');

      //if no history, just push in array & save.
      let _historyList = this.state.historyList;
      _historyList.push(historyToSave);

      this.setState({
        historyList: _historyList,
      }, async () => {
        // localStorage.setItem("LiveQuizHistoryList", JSON.stringify(this.state.historyList));

        if (this.state.isHistoryListSyncDone && this.state.isHistoryListExists_Local) {
          localStorage.setItem("LiveQuizHistoryList_List_" + this.state.user.uid, JSON.stringify(this.state.historyList));
          localStorage.setItem("LiveQuizHistoryList_LU_" + this.state.user.uid, updateDateTime);
        }

        await this.Process_FullHistoryList_ForUpdateToFireStore(this.state.historyList);
        await this.Update_HistoryList_LastUpdate_OnFireStore(updateDateTime);
      });

    }

    // console.log("room history is saved.");
  }
  */
  //#endregion

  //2021.10.14 - currently using (checked 2023.09.12)
  SaveRoomInLocalHistoryList_V2 = async (dataToSave) => {
    if (dataToSave.hasOwnProperty('RoomCode')) {

      // //2022.06.14
      // let _isLate = false;
      // let timeStart = null;
      // if (isDone !== null) {
      //   if (roomInfo !== null)
      //     if (roomInfo.hasOwnProperty('DateStart') && roomInfo.hasOwnProperty('TimeStart'))
      //       timeStart = String(roomInfo.DateStart) + ' ' + String(roomInfo.TimeStart);  //e.g. "YYYY-MM-DD HH:mm"
      // }

      let _room = null;
      await firestore
        .collection('LiveQuizHistory')
        .doc(String(this.state.user.uid))
        .collection('List')
        .where('RoomCode', '==', String(dataToSave.RoomCode))
        .get()
        .then(querySnapshot => {
          let dataArray = [];
          if (querySnapshot !== null) {
            querySnapshot.forEach((doc) => {
              dataArray.push(doc.data());
            });
            if (dataArray.length > 0) {
              _room = dataArray[0];
              // if (_room.hasOwnProperty('RoomType') === false)
              //   _room.RoomType = 0;
            }
          }
        })
        .catch(error => {
          if (this.state.isDevMode)
            console.log(error.message);
        });

      const dtNow = moment.utc().format('YYYY-MM-DD HH:mm:ss');
      const dtTZ = moment().format('Z');    //e.g. 'Z' ="+8:00" , 'ZZ' = "+0800"

      if (_room !== null) {
        if (this.state.isDevMode)
          console.log('SaveRoomInLocalHistoryList_V2 \n Fetch \n ' + JSON.stringify(_room));

        //Update.
        if (_room.hasOwnProperty('Date')) {

          // let updateModal = {
          //   // "Date": _room.Date,
          //   // "RoomCode": _room.RoomCode,
          //   // "RoomId": _room.RoomId,
          //   // "Title": _room.Title,
          //   "Score": dataToSave.Score,
          //   "RoomType": dataToSave.hasOwnProperty('RoomType') ? Number(dataToSave.RoomType) : 0,
          // };

          let setModal = { ..._room };
          let updateModal = {};
          // let isUpdate = true;
          let isUpdate = _room.hasOwnProperty('RoomType');

          //RoomType
          if (dataToSave.hasOwnProperty('RoomType')) {
            setModal['RoomType'] = Number(dataToSave.RoomType);
            if (String(_room.RoomType) !== String(dataToSave.RoomType))
              updateModal['RoomType'] = Number(dataToSave.RoomType);
            // if (_room.hasOwnProperty('RoomType') === false)
            //   isUpdate = false;
            // else if (String(_room.RoomType) !== String(dataToSave.RoomType))
            //   updateModal['RoomType'] = Number(dataToSave.RoomType);
          }

          //Score
          if (dataToSave.hasOwnProperty('Score')) {
            setModal['Score'] = dataToSave.Score;
            if (String(_room.Score) !== String(dataToSave.Score))
              updateModal['Score'] = dataToSave.Score;
            // if (_room.hasOwnProperty('Score') === false)
            //   isUpdate = false;
            // else if (String(_room.Score) !== String(dataToSave.Score))
            //   updateModal['Score'] = dataToSave.Score;
          }

          //LastEnteredDateTimeUtc.   //2022.11.24
          // const dtNow = moment.utc().format('YYYY-MM-DD HH:mm:ss');
          // const dtTZ = moment().format('Z');
          setModal['LastEnteredDateTimeUtc'] = dtNow;
          setModal['LastEnteredTimeZone'] = dtTZ;
          updateModal['LastEnteredDateTimeUtc'] = dtNow;
          updateModal['LastEnteredTimeZone'] = dtTZ;

          // //2023.10.20
          // setModal['SubmittedOnUTC'] = CheckObjectStringEmpty(dataToSave, 'SubmittedOnUTC');
          // setModal['IsDoneOnUtc'] = CheckObjectStringEmpty(dataToSave, 'IsDoneOnUtc');
          // updateModal['SubmittedOnUTC'] = CheckObjectStringEmpty(dataToSave, 'SubmittedOnUTC');
          // updateModal['IsDoneOnUtc'] = CheckObjectStringEmpty(dataToSave, 'IsDoneOnUtc');

          //2023.11.09
          if (CheckObjectNullValue(dataToSave, 'SubmittedOnUTC') !== null) {
            setModal['SubmittedOnUTC'] = CheckObjectStringEmpty(dataToSave, 'SubmittedOnUTC');
            updateModal['SubmittedOnUTC'] = CheckObjectStringEmpty(dataToSave, 'SubmittedOnUTC');
          }
          if (CheckObjectNullValue(dataToSave, 'IsDoneOnUtc') !== null) {
            setModal['IsDoneOnUtc'] = CheckObjectStringEmpty(dataToSave, 'IsDoneOnUtc');
            updateModal['IsDoneOnUtc'] = CheckObjectStringEmpty(dataToSave, 'IsDoneOnUtc');
          }

          //Ref.
          let docRef = firestore.collection('LiveQuizHistory')
            .doc(String(this.state.user.uid))
            .collection('List')
            .doc(moment(String(_room.Date)).format('YYYYMMDDHHmmss'));    //e.g. 20201123142035

          //set or update.
          if (isUpdate) {
            if (Object.keys(updateModal).length > 0) {
              //Update.
              await docRef
                .update(updateModal)
                .then(() => {
                  if (this.state.isDevMode)
                    console.log('history (success) \n (Update) \n ' + JSON.stringify(updateModal));
                })
                .catch(error => {
                  if (this.state.isDevMode)
                    console.log('update history (failed) =\n' + JSON.stringify(error));
                });
            }
            else {
              if (this.state.isDevMode)
                console.log('update history (empty) \n (not needed)');
            }
          }
          else {
            //Set.
            await docRef
              .set(setModal)
              .then(() => {
                if (this.state.isDevMode)
                  console.log('history (success) \n (Set) \n ' + JSON.stringify(setModal));
              })
              .catch(error => {
                if (this.state.isDevMode)
                  console.log('set history (failed) =\n' + JSON.stringify(error));
              });
          }
          // let updateDateTime = moment().format('YYYY-MM-DD HH:mm:ss');
          await this.Update_HistoryList_LastUpdate_OnFireStore(dtNow);
        }
        //done.
      }
      else {
        //New
        // await this.SetHistoryItem(dataToSave);
        const setModal = {
          "Date": dataToSave.Date,
          "RoomCode": dataToSave.RoomCode,
          "RoomId": dataToSave.RoomId,
          "Title": dataToSave.Title,
          "Score": dataToSave.Score,
          "RoomType": dataToSave.hasOwnProperty('RoomType') ? Number(dataToSave.RoomType) : 0,
          // "IsLate": isLate,  //2022.06.14
          "LastEnteredDateTimeUtc": dtNow,
          "LastEnteredTimeZone": dtTZ,
        };
        await firestore.collection('LiveQuizHistory')
          .doc(String(this.state.user.uid))
          .collection('List')
          .doc(moment(dataToSave.Date).format('YYYYMMDDHHmmss'))    //e.g. 20201123142035
          .set(setModal)
          .then(() => {
            if (this.state.isDevMode)
              console.log('history (success) \n (Set) \n ' + JSON.stringify(setModal));
          })
          .catch(error => {
            if (this.state.isDevMode)
              console.log('set (new) history (failed) =\n' + JSON.stringify(error));
          });
        // let updateDateTime = moment().format('YYYY-MM-DD HH:mm:ss');
        await this.Update_HistoryList_LastUpdate_OnFireStore(dtNow);
      }
      //done.
    }
  }

  //2023.11.22
  SaveRoomInLocalHistoryList_ViaApi = async (dataToSave) => {

  }

  // //2022.06.14
  // SetHistoryItem = async (dataToSave) => {
  //   if (dataToSave.hasOwnProperty('Date') === false)
  //     return;

  //   await firestore.collection('LiveQuizHistory')
  //     .doc(String(this.state.user.uid))
  //     .collection('List')
  //     .doc(moment(dataToSave.Date).format('YYYYMMDDHHmmss'))    //e,g, 20201123142035
  //     .set({
  //       "Date": dataToSave.Date,
  //       "RoomCode": dataToSave.RoomCode,
  //       "RoomId": dataToSave.RoomId,
  //       "Title": dataToSave.Title,
  //       "Score": dataToSave.Score,
  //       "RoomType": Number(dataToSave.RoomType),
  //       // "IsLate": CheckBoolean(dataToSave.IsLate),
  //     })
  //     .catch(error => {
  //       if (this.state.isDevMode)
  //         console.log(error.message);
  //     });
  // }

  // SetHistoryExistanceState = (LU_HL_RTDB, LU_HL_FS) => {
  // SetHistoryExistanceState = (isLoaded) => {
  //   this.setState({
  //     // LU_HistoryList_Local: LU_HL_RTDB,
  //     // LU_HistoryList_FS: LU_HL_FS,
  //     // isHistoryListLoaded: isLoaded,
  //     isHistoryListSyncDone: isLoaded,
  //   });
  // }

  SetHistoryListSyncState = (isSyncDone = false, isLocalExists = false, isFsExists = false) => {
    this.setState({
      isHistoryListSyncDone: isSyncDone,
      isHistoryListExists_Local: isLocalExists,
      isHistoryListExists_FS: isFsExists,
    });
  }

  Update_HistoryList_LastUpdate_OnFireStore = async (dateTime) => {
    //Update new Last Update DT on FireStore.

    //2021.09.13 - add retry.
    let retryAgain = false;
    let done = false;
    let async_action = async () => {
      await firestore.collection('LiveQuizHistory')
        .doc(this.state.user.uid)
        .set({
          'LastUpdate': moment(dateTime).format('YYYY-MM-DD HH:mm:ss'),
          'Uid': String(this.state.user.uid)
        })
        // .doc('LastUpdate')
        // .set(moment(dateTime).format('YYYY-MM-DD HH:mm:ss'))   //2021.03.27
        .then(() => {
          // isUpdateSuccess = true;
          done = true;
        })
        .catch((error) => {
          // this.SetAlert("Error", error.code + "<br />" + error.message);
          // await this.SetErrorLog(new Date(), "Update_HistoryList_LastUpdate_OnFireStore | FireStore | Error = " + error);
          retryAgain = true;
          done = true;
        });
      await DelayUntil(() => done === true);
    }
    do {
      retryAgain = false;
      done = false;
      await async_action();
      // await DelayUntil(() => done === true);
      if (retryAgain)
        await Delay(500);
    } while (retryAgain);
  }

  Process_SingleRecord_UpdateTo_HistoryListOnFireStore = async (dataToSave) => {
    let itemId = moment(dataToSave.Date).format('YYYYMMDDHHmmss');
    //2021.09.13 - add retry.
    let retryAgain = false;
    let done = false;
    let async_action = async () => {
      await firestore.collection('LiveQuizHistory')
        .doc(this.state.user.uid)
        .collection('List')
        .doc(itemId)    //e,g, 20201123142035
        .set({
          "Date": dataToSave.Date,
          "RoomCode": dataToSave.RoomCode,
          "RoomId": dataToSave.RoomId,
          "Title": dataToSave.Title,
          "Score": dataToSave.Score,
        })
        .then(() => {
          // isUpdateSuccess = true;
          done = true;
        })
        .catch((error) => {
          // isUpdateSuccess = false;
          // this.SetAlert("Error", error.code + "<br />" + error.message);
          // await this.SetErrorLog(new Date(), "Process_SingleRecord_UpdateTo_HistoryListOnFireStore | FireStore | Error = " + error);
          retryAgain = true;
          done = true;
        });
      await DelayUntil(() => done === true);
    }
    do {
      retryAgain = false;
      done = false;
      await async_action();
      // await DelayUntil(() => done === true);
    } while (retryAgain);
  }

  Process_FullHistoryList_ForUpdateToFireStore = async (historyListToSave) => {

    let itemId = '';
    historyListToSave.map(async (data, key) => {
      itemId = moment(data.Date).format('YYYYMMDDHHmmss');
      await firestore.collection('LiveQuizHistory')
        .doc(this.state.user.uid)
        .collection('List')
        .doc(itemId)    //e,g, 20201123142035
        .set({
          "Date": data.Date,
          "RoomCode": data.RoomCode,
          "RoomId": data.RoomId,
          "Title": data.Title,
        })
        .then(() => {
          // isUpdateSuccess = true;
        })
        .catch(async (error) => {
          // isUpdateSuccess = false;
          // this.SetAlert("Error", error.code + "<br />" + error.message);
          await this.SetErrorLog(new Date(), "Process_FullHistoryList_ForUpdateToFireStore | FireStore | Error = " + error);
        });
      return null;
    });

  }

  //==== Quiz History List === ends.


  //==== Localization Language Setting ==== starts.

  LoadLocalizatioLanguageSetting = () => {
    let localeSetting = localStorage.getItem("LocaleSetting");
    this.setState({
      Locale: localeSetting !== null ? localeSetting : Lang.English,
    });
  }

  SetLocaleSetting = (setting) => {
    this.setState({
      Locale: setting !== null ? setting : Lang.English,

      //2022.11.01
      GenderOptions: [],
      RaceOptions: [],
    }, () => {
      localStorage.setItem("LocaleSetting", setting);
      // this.ResetOptionsLabel();
      if (this.state.currentScreen === Screen.HomeScreen) {
        if (this.QuizHome_Ref.current !== null) {
          // this.QuizHome_Ref.current.PopulateGradeOptions();
          this.QuizHome_Ref.current.ResetOptionsLabel();
        }
      }
      else if (this.state.currentScreen === Screen.SignUpScreen) {    //2023.10.31
        if (this.SignUpScreen_Ref.current !== null) {
          this.SignUpScreen_Ref.current.ResetOptionsLabel();
        }
      }
    });
  }

  // //2022.11.01
  // ResetOptionsLabel = () => {
  //   this.setState({
  //     GenderOptions: [
  //       { value: Locale("label-gender-male", Lang.English), label: Locale("label-gender-male", this.state.Locale) },
  //       { value: Locale("label-gender-female", Lang.English), label: Locale("label-gender-female", this.state.Locale) },
  //       { value: Locale("label-gender-other", Lang.English), label: Locale("label-gender-other", this.state.Locale) },
  //     ],
  //     RaceOptions: [
  //       { value: Locale("label-race-1", Lang.English), label: Locale("label-race-1", this.state.Locale) },
  //       { value: Locale("label-race-2", Lang.English), label: Locale("label-race-2", this.state.Locale) },
  //       { value: Locale("label-race-3", Lang.English), label: Locale("label-race-3", this.state.Locale) },
  //       { value: Locale("label-race-0", Lang.English), label: Locale("label-race-0", this.state.Locale) },
  //     ],
  //   }, () => {
  //     // console.log(JSON.stringify(this.state.GenderOptions));
  //     // console.log(JSON.stringify(this.state.RaceOptions));
  //     if (this.QuizHome_Ref.current !== null)
  //       this.QuizHome_Ref.current.PopulateGradeOptions();
  //   });
  // }

  //==== Localization Language Setting ==== ends.


  //==== side load page component ==== starts.

  // //2022.10.22
  // PageComponent_QuizLive = async () => {
  //   let pageComponent = this.state.PageComponent_QuizLive;
  //   if (pageComponent === null) {
  //     pageComponent = await import('../Quiz/QuizLive');
  //     pageComponent = React.lazy(() => import('../Quiz/QuizLive'));
  //   }
  //   return pageComponent;
  // }

  //==== side load page component ==== ends.


  //#region Internal use - admin tool

  //2023.11.14
  UpdateProfileAndSyncToDB = async () => {
    this.setState({ admin_processing: true });
    console.log('UpdateProfileAndSyncToDB', 'started');

    // const profiles = ['5YpCggPntGRyBNbZDHyElihw2vj1', 'IjLTvqMkoKVKkO4DtqB4DXrNPUh1', 'ImdopKB2ajOz3CE80ZLUsARQ0cg2'];
    const profiles = [];  //TempProfileUids;

    if (profiles.length > 0) {
      for (let p = 0; p < profiles.length; p++) {
        const uid = profiles[p];
        let profileData = null;
        await firestore.collection('User').where('Uid', '==', uid).get().then((querySnapshot) => {
          let data = [];
          if (querySnapshot !== null) {
            querySnapshot.forEach((doc) => {
              data.push(doc.data());
            });
          }
          if (data.length > 0)
            profileData = data[0];
        });
        if (profileData !== null) {
          const { profile, isUpdateToFirestoreNeeded } = Profile_ValidateProperties(profileData, profileData);
          if (isUpdateToFirestoreNeeded) {
            firestore.collection('User').doc(uid).set(profile);
            console.log('UpdateProfileAndSyncToDB', '(updated)', (p + 1), profile['Name'], uid);

            await fetch(GlobalSetting.ApiUrl
              + 'Api/LearningCentre/User/Registration/Profile/Sync',
              {
                method: 'POST',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  FirebaseUserId: uid,
                  OrganizerIdentity: null,
                  EventCode: null,            //for registration only.
                  CenterUserId: null,         //for sync event setting.
                  AuthorId: null,             //for sync event setting.
                  RegistrationDate: null,     //for registration only.
                  Email: CheckObjectStringEmpty(profileData, 'Email'),    //2024.05.06
                })
              })
              .then(res => res.json())
              .then((data) => {
                console.log('UpdateProfileAndSyncToDB', '(sync)', (data.success ? '(success)' : '(failed)'), (p + 1), profile['Name'], uid);
              })
              .catch(error => {
                console.log('UpdateProfileAndSyncToDB', '(sync)', '(error)', error.message, (p + 1), profile['Name'], uid);
              });
          }
          else {
            console.log('UpdateProfileAndSyncToDB', '(skipped)', (p + 1), profile['Name'], uid);
          }
        }
      }
    }
    this.setState({ admin_processing: false });
    console.log('UpdateProfileAndSyncToDB', 'ended');
  }


  //2023.11.07 - replaced by api 'Api/LearningCentre/Admin/UpdateOwnershipForQuestionSetAndCorrespondingItems/{qSetUniqueId}'
  UpdateOwnershipForQuestionSetAndCorrespondingItems = async () => {
    this.setState({
      admin_processing: true,
    });
    // let com = document.getElementById('input-question-set-unique-id');
    // if (com !== null) {
    //   if (CheckNullValue(com.value) !== null) {
    //     const qSet_uniqueId = CheckStringEmpty(com.value);
    //     console.log('qSet_uniqueId =', qSet_uniqueId);

    //     //fetch Question Set.
    //     let qSet = null;
    //     await this.props.firestore
    //       .collection("QuizBank")
    //       .doc('QuizQuestionSet')
    //       .collection('QuizQuestionSets')
    //       .where('UniqueId', '==', qSet_uniqueId)
    //       .get()
    //       .then(querySnapshot => {
    //         let dataArray = [];
    //         if (querySnapshot !== null) {
    //           querySnapshot.forEach((doc) => {
    //             dataArray.push(doc.data());
    //           });
    //           if (dataArray.length > 0)
    //             qSet = dataArray[0];
    //         }
    //       })
    //       .catch(error => {
    //         console.log(error.message);
    //       });

    //     //Update Question Set.
    //     // let canPost = false;
    //     if (qSet !== null) {
    //       // const origin = JSON.parse(JSON.stringify(qSet));

    //       qSet['AuthorId'] = 50;
    //       qSet['CenterUserId'] = 0;
    //       qSet['OrganizerId'] = 8;

    //       // canPost = JSON.stringify(origin) !== JSON.stringify(qSet);
    //     }

    //     //Post Question Set to Firebase.
    //     if (qSet !== null) {
    //       //call api 'Api/LearningCentre/QuizBank/QuestionSet/Settings/Update'
    //       //Call CMS APi for update actione.
    //       let isUpdateSuccess = false;
    //       let message = '';
    //       let jsonModel = {
    //         OrganizerId: 8,
    //         CenterUserId: 0,
    //         AuthorId: 50,
    //         FirebaseUserId: 'fb4036b6-460f-46df-81b1-866dbe333ab9',

    //         UniqueId: CheckObjectStringEmpty(qSet, 'UniqueId'),
    //         Name: CheckObjectStringEmpty(qSet, 'Name'),
    //         Remark: CheckObjectStringEmpty(qSet, 'Remark'),
    //         TotalQuestion: Number(qSet, 'TotalQuestion'),
    //         GroupId: Number(qSet.Group.Id),
    //         GroupName: CheckStringEmpty(qSet.Group.Name),

    //         SubjectId: Number(qSet.Subject.Id),
    //         SubjectName: CheckStringEmpty(qSet.Subject.Name),

    //         DisplayOrder: CheckObjectNumber(qSet, 'DisplayOrder'),
    //         IsPublic: CheckObjectBoolean(qSet, 'IsPublic'),
    //         IsPrivateGroup: CheckObjectBoolean(qSet, 'IsPrivateGroup'),
    //         Published: CheckObjectBoolean(qSet, 'Published'),

    //         IsRemoveQuestion: false,
    //         QuestionUniqueId: '',
    //       };
    //       console.log('question set setting modal =\n' + JSON.stringify(jsonModel));

    //       await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/QuizBank/QuestionSet/Settings/Update',
    //         {
    //           method: 'POST',
    //           headers: {
    //             'Accept': 'application/json',
    //             'Content-Type': 'application/json',
    //           },
    //           body: JSON.stringify(jsonModel),
    //         })
    //         .then(res => res.json())
    //         .then(data => {
    //           console.log('qset update = ' + JSON.stringify(data));

    //           isUpdateSuccess = data.success;
    //           message = 'api - update question set settings ('
    //             + (data.success ? 'success' : 'failed')
    //             + ').\n' + JSON.stringify(data);
    //         })
    //         .catch(error => {
    //           message = 'Error : ' + error.message + ' | \n' + JSON.stringify(jsonModel);
    //         });
    //       console.log('qset update', message);
    //     }

    //     //all done.
    //   }
    //   else {
    //     console.log('invalid input value.');
    //   }
    // }
    this.setState({
      admin_processing: false,
    });
  }

  //2023.11.17
  UpdateOrganinerDetailsForQuizRoom = async () => {
    const organizerId = 8;
    const organizerIdentity = 'sjkctamanconnaught';
    const organizerDisplayName = 'SEKOLAH JENIS KEBANGSAAN (CINA) TAMAN CONNAUGHT';

    this.setState({
      admin_processing: true,
    });

    let _List = [];
    await firestore.collection('LiveQuiz_UniqueRoomCode').where('OrganizerId', '==', organizerId).get().then((querySnapshot) => {
      let data = [];
      if (querySnapshot !== null) {
        querySnapshot.forEach((doc) => {
          data.push(doc.data());
        });
      }
      if (data.length > 0)
        _List = data;
    });
    if (_List.length > 0) {
      for (let i = 0; i < _List.length; i++) {
        const listItem = _List[i];
        if (CheckObjectNullValue(listItem, 'RoomId') !== null && CheckObjectNullValue(listItem, 'RoomCode') !== null) {
          let room = {};
          room['OrganizerIdentity'] = organizerIdentity;
          room['Organizer'] = organizerDisplayName;

          //update to FS.
          await firestore.collection('LiveQuiz_UniqueRoomCode').doc(CheckObjectStringEmpty(listItem, 'RoomId'))
            .update(room).catch(error => {
              console.log('save room FS (error) =\n' + error, JSON.stringify(listItem));
            });

          //update to cms.
          await fetch(GlobalSetting.ApiUrl
            + 'Api/LearningCentre/Quiz/Room/Sync/'
            + 0 + '/'
            + 50 + '/'
            + CheckObjectStringEmpty(listItem, 'RoomCode') + '/'
            + CheckObjectStringEmpty(listItem, 'RoomId'),
            // Api/LearningCentre/Quiz/Room/Sync/{centerUserId}/{authorId}/{roomcode}/{room_id}
            {
              method: 'GET',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
              },
            })
            .then(res => res.json())
            .then(data => {
              if (!data.success)
                console.log('Error', 'api - room sync (failed)\n' + JSON.stringify(data), JSON.stringify(listItem));
            })
            .catch(error => {
              console.log('Error', 'api - room sync (failed)\n' + error.message, JSON.stringify(listItem));
            });

          console.log('UpdateOrganinerDetailsForQuizRoom', 'done', listItem['RoomCode'], listItem['RoomId'])
        }
        else {
          console.log('UpdateOrganinerDetailsForQuizRoom', 'Invalid RoomCode or RoomId.', JSON.stringify(_List[i]));
        }
      }
    }

    this.setState({
      admin_processing: false,
    });
  }

  //#endregion

  render = () => {
    // if (!this.state.isSplashDelayDone) {
    //   return (
    //     <div style={{
    //       backgroundColor: 'pink', height: '100%', width: '100%',
    //       position: 'absolute',
    //       justifyContent: 'center',
    //       alignItems: 'center',
    //       padding: 50
    //     }}>
    //       <div class="container" style={{ width: '550px' }}>
    //         <div class="row">
    //           <aside class="col-sm-12">

    //             <p style={{
    //               color: 'black', fontSize: 35, textAlign: 'center', fontWeight: 'bold',
    //             }}>Splash Screen</p>

    //           </aside>
    //         </div>
    //       </div>
    //     </div>
    //   );
    // }
    // else {
    return (
      <div style={{
        // height: '100%',
        // position: 'absolute',
        // left: '0px',
        // width: '100%',
        // overflow: 'hidden'
      }}>
        <Router>
          {/* <div
          // style={{
          //   backgroundColor: 'lavender',
          // }}
          > */}
          {/* <nav>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about">About</Link>
                </li>
                <li>
                  <Link to="/users">Users</Link>
                </li>
                <li>
                  <Link to="/signlogin">SignLogin</Link>
                </li>
              </ul>
            </nav> */}

          {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
          <Switch>
            {/* <Suspense fallback={<ProgressBar animated now={100} />}> */}

            {/* Admin Section - start */}
            <Route path="/admin/quiz/admintool" key={'K_QuizAdminTool'}>
              <QuizAdminTool
                key={'QuizAdminTool'}   //originally is QuizMaAnagement before 2021.06.30

                Locale={this.state.Locale}
                SetLocaleSetting={this.SetLocaleSetting}

                user={this.state.user}
                isSuperAdmin={this.state.isSuperAdmin}

                firestore={firestore}     //Everything
                dbLiveQuiz={dbLiveQuiz}
                dbLiveQuizResult={dbLiveQuizResult}   //2023.10.30
                dbLiveQuizLogs={dbLiveQuizLogs}       //2023.10.30
                dbLiveQuizRoomResult={dbLiveQuizRoomResult}   //2023.11.07
                dbCommon={dbCommon}
                // dbQuestion={dbQuestion}
                dbLogs={dbLogs}
                dbQuizBank={dbQuizBank}   //Quiz Bank
                dbLQ_SN_Counter={dbLQ_SN_Counter}     //2021.11.08

                SetLoading={this.SetLoading}
                SetAlert={this.SetAlert}
                CloseAlert={this.CloseAlert}
                SetLog={this.SetLog}
                SetUserLog={this.SetUserLog}  //2023.10.30
                SetLiveQuizLog={this.SetLiveQuizLog}  //2023.10.30

                // //2021.01.13
                // Events={this.state.EventList}

                //2021.08.16
                Events={useDefaultEvents ? DefaultEvents : this.state.EventList}
                // Events={DefaultEvents}

                //2021.4.07
                isDevMode={this.state.isDevMode}
              />
            </Route>

            <Route path="/admin/quiz/management" key={'K_QuizManagement'}>
              <QuizManagement
                key={'QuizManagement'}

                defaultCustomPermissionModel_allowed={DefaultCustomPermissionModel_Allowed}
                defaultCustomPermissionModel_restricted={DefaultCustomPermissionModel_Restricted}
                defaultCustomPermissions={DefaultCustomPermissions}

                user={this.state.user}
                isSuperAdmin={this.state.isSuperAdmin}
                isAuthor={this.state.isAuthor}
                isDevMode={this.state.isDevMode}
                isFromParentApp={this.state.isFromParentApp}
                SetUserDataOnly={this.SetUserDataOnly}

                Locale={this.state.Locale}
                SetLocaleSetting={this.SetLocaleSetting}

                firestore={firestore}     //FS
                firebase={firebase}       //Login, path, etc.
                dbLiveQuiz={dbLiveQuiz}
                dbLiveQuizResult={dbLiveQuizResult}   //2023.10.30
                dbLiveQuizLogs={dbLiveQuizLogs}       //2023.10.30
                dbLiveQuizRoomResult={dbLiveQuizRoomResult}   //2023.11.07
                dbCommon={dbCommon}
                dbQuestion={dbQuestion}
                dbLogs={dbLogs}
                dbQuizBank={dbQuizBank}   //Quiz Bank
                dbLQ_SN_Counter={dbLQ_SN_Counter}     //2021.11.08
                dbQuestionSet={dbQuestionSet}   //2023.09.09

                // SetAlert={this.SetAlert}
                Feedback={Feedback}
                SetLoading={this.SetLoading}
                SetAlert={this.SetAlert}
                SetAlertConfirm={this.SetAlertConfirm}
                SetAlertWithProgressBar={this.SetAlertWithProgressBar}
                // SetAlertWithComponent={this.SetAlertWithComponent}
                CloseAlert={this.CloseAlert}
                SetLog={this.SetLog}
                SetUserLog={this.SetUserLog}  //2023.10.30
                SetLiveQuizLog={this.SetLiveQuizLog}  //2023.10.30

                // Events={DefaultEvents}
                Events={useDefaultEvents ? DefaultEvents : this.state.EventList}
                ResetEventList={this.ResetEventList}  //2021.09.01
                CheckIfRoomBelongsToEvent={this.CheckIfRoomBelongsToEvent}
                GroupId={GroupId}
                SubjectId={SubjectId}

                //2021.10.23
                credential={this.state.Credential}  //aka Firebase Login Credential
                profile={this.state.profile}

                //2022.06.27
                Screen={Screen}
                SetScreen={this.SetScreen}
              />
            </Route>

            <Route path="/admin" key={'K_AdminHome'}>
              <AdminHome
                key={'AdminHome'}

                user={this.state.user}
                isSuperAdmin={this.state.isSuperAdmin}
                isAuthor={this.state.isAuthor}
              />
            </Route>
            {/* Admin Section - end */}

            <Route path="/special/ai/list/edit/room" key={'K_AiListEditRoom'}>
              <QuizBankListAndEditDocRoomForSpecialWebPortal
                key={'AiListEditRoom'}

                user={this.state.user}
                isSuperAdmin={this.state.isSuperAdmin}
                isAuthor={this.state.isAuthor}
                profile={this.state.profile}
                email={this.state.email}

                RoomCode={this.state.quizRoomCode}
                RoomId={this.state.quizRoomId}
                RoomDate={this.state.quizRoomDate}    //2021.07.14

                firestore={firestore}     //2021.04.06
                dbLiveQuiz={dbLiveQuiz}
                dbLiveQuizResult={dbLiveQuizResult}   //2023.10.30
                dbLiveQuizLogs={dbLiveQuizLogs}       //2023.10.30
                dbLiveQuizRoomResult={dbLiveQuizRoomResult}   //2023.11.07
                dbCommon={dbCommon}
                // dbQuestion={dbQuestion}
                dbLogs={dbLogs}
                dbQuizBank={dbQuizBank}   //Quiz Bank
                dbLQ_SN_Counter={dbLQ_SN_Counter}     //2021.11.12

                // setRoomCodeId={this.setRoomCodeId}
                SetLoading={this.SetLoading}
                SetAlert={this.SetAlert}
                SetAlertWithProgressBar={this.SetAlertWithProgressBar}
                CloseAlert={this.CloseAlert}

                // SetLog={this.SetLog}
                // SetUserLog={this.SetUserLog}  //2023.10.30
                // SetLiveQuizLog={this.SetLiveQuizLog}  //2023.10.30
                // SetErrorLog={this.SetErrorLog}  //added on 2020.11.10

                viewHistoryQuiz={this.state.viewHistoryQuiz}
                historyList={this.state.historyList}
                SetHistoryList={this.SetHistoryList}
                // SaveRoomInLocalHistoryList={this.SaveRoomInLocalHistoryList}
                SaveRoomInLocalHistoryList_V2={this.SaveRoomInLocalHistoryList_V2}  //2021.10.14
                // UpdateScoreInRoomHistoryList={this.UpdateScoreInRoomHistoryList}

                //2020.11.26
                isFromParentApp={this.state.isFromParentApp}

                //2021.04.06
                eventCode={this.state.eventCode}
                // SetEventInfo={this.SetEventInfo}
                // CheckIfRoomBelongsToEvent={this.CheckIfRoomBelongsToEvent}
                Events={useDefaultEvents ? DefaultEvents : this.state.EventList}  //2021.11.08
                CheckIfRoomBelongsToEvent={this.CheckIfRoomBelongsToEvent}
                LoadAllEvents={this.LoadAllEvents}  //2021.11.12

                //2021.04.09
                isDevMode={this.state.isDevMode}

                //2021.10.23
                credential={this.state.Credential}  //aka Firebase Login Credential

              />
            </Route>

            <Route path="/participatedEventList" key={'K_ParticipatedEventListScreen'} >
              <ParticipatedEventList
                key={'ParticipatedEventListScreen'}

                Locale={this.state.Locale}
                SetLocaleSetting={this.SetLocaleSetting}

                user={this.state.user}
                email={this.state.email}
                profile={this.state.profile}

                auth={auth}
                firestore={firestore}
                dbLiveQuiz={dbLiveQuiz}
                dbLiveQuizResult={dbLiveQuizResult}   //2023.10.30
                dbLiveQuizLogs={dbLiveQuizLogs}       //2023.10.30
                dbLiveQuizRoomResult={dbLiveQuizRoomResult}   //2023.11.07
                dbCommon={dbCommon}
                dbQuestion={dbQuestion}
                dbLogs={dbLogs}
                dbLQ_SN_Counter={dbLQ_SN_Counter}     //2021.11.08

                SetLoading={this.SetLoading}
                SetAlert={this.SetAlert}
                SetAlertWithProgressBar={this.SetAlertWithProgressBar}
                SetAlertWithComponent={this.SetAlertWithComponent}
                CloseAlert={this.CloseAlert}

                Screen={Screen}
                SetScreen={this.SetScreen}

                setRoomCodeId={this.setRoomCodeId}
                SetViewHistoryQuiz={this.SetViewHistoryQuiz}

                Feedback={Feedback}
                isInternetReachable={this.state.isInternetReachable}
                ShowInternetNotAvailableAlert={this.ShowInternetNotAvailableAlert}

                //2021.04.06
                CheckIfRoomBelongsToEvent={this.CheckIfRoomBelongsToEvent}
                isDevMode={this.state.isDevMode}

                //2021.04.12
                isFromParentApp={this.state.isFromParentApp}

                //2021.09.14
                Events={useDefaultEvents ? DefaultEvents : this.state.EventList}
              />
            </Route>


            {/* National Quiz Event SignUp */}
            {/* <Route path="/national_quiz_event_signup" key={'K_SignUpScreen_NationalQuizEvent'}> */}
            <Route path="/signup" key={'K_SignUpScreen_v2'}>
              <SignUpScreenV2
                key={'SignUpScreen'}
                ref={this.SignUpScreen_Ref}

                Locale={this.state.Locale}
                SetLocaleSetting={this.SetLocaleSetting}

                user={this.state.user}
                email={this.state.email}
                password={this.state.password}
                loginResponse={this.state.Credential}
                isLoginDone={this.state.isLoginDone}    //2023.10.31

                dbCommon={dbCommon}
                firestore={firestore}
                auth={auth}

                SetLoading={this.SetLoading}
                SetAlert={this.SetAlert}
                SetAlertWithProgressBar={this.SetAlertWithProgressBar}
                SetAlertConfirm={this.SetAlertConfirm}
                Feedback={Feedback}
                CloseAlert={this.CloseAlert}

                SetLog={this.SetLog}
                SetUserLog={this.SetUserLog}  //2023.10.30
                SetLiveQuizLog={this.SetLiveQuizLog}  //2023.10.30
                SetErrorLog={this.SetErrorLog}

                login={this.handleLogin}
                loginState={this.state.LoginState}
                setLoginState={this.SetLoginState}
                resetUserLogin={this.resetUserLogin}
                newUser={this.newUserLogin}

                //2020.11.27
                setEmailPassword={this.setEmailPassword}
                isFromParentApp={this.state.isFromParentApp}
                SetIsFromParentApp={this.SetIsFromParentApp}
                // isRedirectToHistoryList={this.state.isRedirectToHistoryList}
                // SetIsRedirectToHistoryList={this.SetIsRedirectToHistoryList}

                //2020.11.30
                isInternetReachable={this.state.isInternetReachable}
                ShowInternetNotAvailableAlert={this.ShowInternetNotAvailableAlert}

                //2020.12.03
                isEventRegistration={this.state.isEventRegistration}
                eventName={this.state.eventName}
                eventCode={this.state.eventCode}    //2021.01.08
                SetEventInfo={this.SetEventInfo}    //2022.05.12
                centerName={this.state.centerName}
                eventModal={this.state.eventModal}  //2021.02.10
                centerDisplayName={this.state.centerDisplayName}  //2021.03.18
                Events={useDefaultEvents ? DefaultEvents : this.state.EventList}  //2021.08.25
                SetIsEvent={this.SetIsEvent}
                IsActiveEventListLoaded={this.state.IsActiveEventListLoaded}  //2021.09.15

                //2022.06.03
                evtCenterUserId={this.state.evt_centerUserId}
                evtAuthorId={this.state.evt_authorId}

                // //2021.07.24
                // MobileSignUpPageReturnBypass={this.state.MobileSignUpPageReturnBypass}
                // SetMobileSignUpPageReturnBypass={this.SetMobileSignUpPageReturnBypass}

                //2021.07.29
                OrganizerIdentity={this.state.OrganizerIdentity}
                // SetOrganizerIdentity={this.SetOrganizerIdentity}

                //2021.08.18
                isDevMode={this.state.isDevMode}
                Screen={Screen}
                SetScreen={this.SetScreen}
              />
            </Route>


            {/* New - QUiz - Room - (Type) Upload File - added 2021.10.11 - */}
            <Route path="/quiz/roomTypeUploadFile" key={'K_QuizRoomTypeUploadFile'}>
              <QuizRoomTypeFileUpload
                key={'QuizRoomTypeUploadFile'}

                Locale={this.state.Locale}
                SetLocaleSetting={this.SetLocaleSetting}

                user={this.state.user}
                isSuperAdmin={this.state.isSuperAdmin}
                isAuthor={this.state.isAuthor}
                profile={this.state.profile}
                email={this.state.email}

                RoomCode={this.state.quizRoomCode}
                RoomId={this.state.quizRoomId}
                RoomDate={this.state.quizRoomDate}    //2021.07.14

                firestore={firestore}     //2021.04.06
                dbLiveQuiz={dbLiveQuiz}
                dbLiveQuizResult={dbLiveQuizResult}   //2023.10.30
                dbLiveQuizLogs={dbLiveQuizLogs}       //2023.10.30
                dbLiveQuizRoomResult={dbLiveQuizRoomResult}   //2023.11.07
                dbCommon={dbCommon}
                // dbQuestion={dbQuestion}
                dbLogs={dbLogs}
                dbQuizBank={dbQuizBank}   //Quiz Bank
                // dbLQ_SN_Counter={dbLQ_SN_Counter}     //2021.11.12

                // setRoomCodeId={this.setRoomCodeId}
                SetLoading={this.SetLoading}
                SetAlert={this.SetAlert}
                SetAlertWithProgressBar={this.SetAlertWithProgressBar}
                CloseAlert={this.CloseAlert}

                // SetLog={this.SetLog}
                // SetUserLog={this.SetUserLog}  //2023.10.30
                // SetLiveQuizLog={this.SetLiveQuizLog}  //2023.10.30
                // SetErrorLog={this.SetErrorLog}  //added on 2020.11.10

                viewHistoryQuiz={this.state.viewHistoryQuiz}
                historyList={this.state.historyList}
                SetHistoryList={this.SetHistoryList}
                // SaveRoomInLocalHistoryList={this.SaveRoomInLocalHistoryList}
                SaveRoomInLocalHistoryList_V2={this.SaveRoomInLocalHistoryList_V2}  //2021.10.14
                // UpdateScoreInRoomHistoryList={this.UpdateScoreInRoomHistoryList}

                //2020.11.26
                isFromParentApp={this.state.isFromParentApp}

                //2021.04.06
                eventCode={this.state.eventCode}
                // SetEventInfo={this.SetEventInfo}
                // CheckIfRoomBelongsToEvent={this.CheckIfRoomBelongsToEvent}
                Events={useDefaultEvents ? DefaultEvents : this.state.EventList}  //2021.11.08
                CheckIfRoomBelongsToEvent={this.CheckIfRoomBelongsToEvent}

                //2021.04.09
                isDevMode={this.state.isDevMode}

                //2021.10.23
                credential={this.state.Credential}  //aka Firebase Login Credential
              />
            </Route>


            <Route path="/quiz/live" key={'K_QuizLive'}>
              <QuizLive
                key={'QuizLive'}
                ref={this.QuizLive_Ref}

                Locale={this.state.Locale}
                SetLocaleSetting={this.SetLocaleSetting}

                user={this.state.user}
                profile={this.state.profile}
                RoomCode={this.state.quizRoomCode}
                RoomId={this.state.quizRoomId}
                RoomDate={this.state.quizRoomDate}    //2021.07.14
                LastUpdateDate={this.state.LastUpdateDate}  //2023.10.19

                firestore={firestore}     //2021.04.06
                dbLiveQuiz={dbLiveQuiz}
                dbLiveQuizResult={dbLiveQuizResult}   //2023.10.30
                dbLiveQuizLogs={dbLiveQuizLogs}       //2023.10.30
                dbLiveQuizRoomResult={dbLiveQuizRoomResult}   //2023.11.07
                dbCommon={dbCommon}
                dbQuestion={dbQuestion}
                dbLogs={dbLogs}
                dbQuizBank={dbQuizBank}   //Quiz Bank
                dbLQ_SN_Counter={dbLQ_SN_Counter}     //2021.11.08

                // setRoomCodeId={this.setRoomCodeId}
                SetLoading={this.SetLoading}
                SetAlert={this.SetAlert}
                CloseAlert={this.CloseAlert}
                SetAlertConfirm={this.SetAlertConfirm}  //2023.10.18

                SetLog={this.SetLog}
                SetUserLog={this.SetUserLog}  //2023.10.30
                SetLiveQuizLog={this.SetLiveQuizLog}  //2023.10.30
                SetErrorLog={this.SetErrorLog}  //added on 2020.11.10
                SetTimeLeftText={this.SetTimeLeftText}

                viewHistoryQuiz={this.state.viewHistoryQuiz}
                historyList={this.state.historyList}
                SetHistoryList={this.SetHistoryList}
                // SaveRoomInLocalHistoryList={this.SaveRoomInLocalHistoryList}
                SaveRoomInLocalHistoryList_V2={this.SaveRoomInLocalHistoryList_V2}  //2021.10.14
                // UpdateScoreInRoomHistoryList={this.UpdateScoreInRoomHistoryList}

                //2020.11.26
                isFromParentApp={this.state.isFromParentApp}

                //2020.11.27
                isPolicyTncStateTheLatest={this.state.isPolicyTncStateTheLatest}

                //2020.11.30
                isInternetReachable={this.state.isInternetReachable}
                ShowInternetNotAvailableAlert={this.ShowInternetNotAvailableAlert}

                //2020.12.11
                // reEnterQuizRoom={this.state.reEnterQuizRoom}
                SetReEnterQuizRoom={this.SetReEnterQuizRoom}

                //2021.04.06
                eventCode={this.state.eventCode}
                SetEventInfo={this.SetEventInfo}
                CheckIfRoomBelongsToEvent={this.CheckIfRoomBelongsToEvent}

                //2021.04.09
                isDevMode={this.state.isDevMode}
                Screen={Screen}
                SetScreen={this.SetScreen}
                Feedback={Feedback}
              />
            </Route>

            {/* <Route path="/quiz/home">
                <QuizScreen
                  user={this.state.user}
                  logout={this.handleLogout}
                  firebase={firebase}
                  dbLiveQuiz={dbLiveQuiz}
                  setRoomCodeId={this.setRoomCodeId}
                />
              </Route> */}

            {/* <Route path='/history' key={'K_HistoryHome'}>
                <QuizHistoryHome

                  key={'QuizHistoryHome'}

                  user={this.state.user}
                  // email={this.state.email}
                  // password={this.state.password}                  

                  firestore={firestore}
                  auth={auth}

                  Locale={this.state.Locale}
                  SetLocaleSetting={this.SetLocaleSetting}

                  SetAlert={this.SetAlert}
                  SetAlertWithProgressBar={this.SetAlertWithProgressBar}
                  CloseAlert={this.CloseAlert}
                  SetErrorLog={this.SetErrorLog}

                  historyList={this.state.historyList}
                  SetHistoryList={this.SetHistoryList}
                  SetViewHistoryQuiz={this.SetViewHistoryQuiz}
                  setRoomCodeId={this.setRoomCodeId}

                  //2020.11.25
                  isHistoryListSyncDone={this.state.isHistoryListSyncDone}
                  SetHistoryListSyncState={this.SetHistoryListSyncState}

                  //2020.11.26
                  isFromParentApp={this.state.isFromParentApp}
                  SetIsFromParentApp={this.SetIsFromParentApp}

                  //2020.11.27
                  logout={this.handleLogout}

                />
              </Route> */}

            <Route path="/home" key={'K_QuizHome'}>
              <QuizHome
                ref={this.QuizHome_Ref}
                key={'QuizHome'}

                Locale={this.state.Locale}
                SetLocaleSetting={this.SetLocaleSetting}

                email={this.state.email}
                password={this.state.password}

                user={this.state.user}
                isSuperAdmin={this.state.isSuperAdmin}

                profile={this.state.profile}
                SetProfile={this.SetProfile}

                dbLiveQuiz={dbLiveQuiz}
                dbLiveQuizResult={dbLiveQuizResult}   //2023.10.30
                dbLiveQuizLogs={dbLiveQuizLogs}       //2023.10.30
                dbLiveQuizRoomResult={dbLiveQuizRoomResult}   //2023.11.07
                dbLogs={dbLogs}
                dbCommon={dbCommon}
                dbProfileBackUp={dbProfileBackUp}
                firestore={firestore}
                auth={auth}

                logout={this.handleLogout}
                setRoomCodeId={this.setRoomCodeId}
                resetUserLogin={this.resetUserLogin}
                SetLog={this.SetLog}
                SetUserLog={this.SetUserLog}  //2023.10.30
                SetLiveQuizLog={this.SetLiveQuizLog}  //2023.10.30
                SetErrorLog={this.SetErrorLog}  //added on 2020.11.11

                SetLoading={this.SetLoading}
                SetAlert={this.SetAlert}
                SetAlertWithProgressBar={this.SetAlertWithProgressBar}
                CloseAlert={this.CloseAlert}

                historyList={this.state.historyList}
                SetHistoryList={this.SetHistoryList}
                SetViewHistoryQuiz={this.SetViewHistoryQuiz}
                viewHistoryQuiz={this.state.viewHistoryQuiz}
                // editHistory={this.CustomEditHistoryList}

                //2020.11.25
                isHistoryListSyncDone={this.state.isHistoryListSyncDone}
                SetHistoryListSyncState={this.SetHistoryListSyncState}

                //2020.11.26
                isFromParentApp={this.state.isFromParentApp}
                isRedirectToHistoryList={this.state.isRedirectToHistoryList}

                //2020.11.27
                isPolicyTncStateTheLatest={this.state.isPolicyTncStateTheLatest}
                SetIsPolicyTncStateTheLatest={this.SetIsPolicyTncStateTheLatest}

                //2020.11.30
                isInternetReachable={this.state.isInternetReachable}
                ShowInternetNotAvailableAlert={this.ShowInternetNotAvailableAlert}

                //2020.12.04
                // Events={DefaultEvents}
                Events={useDefaultEvents ? DefaultEvents : this.state.EventList}  //2021.08.16
                ResetEventList={this.ResetEventList}  //2021.09.01
                LoadAllEvents={this.LoadAllEvents}    //2021.09.01
                UniqueEventCode={this.state.uniqueEventCode}  //2021.07.01
                SetEventInfo={this.SetEventInfo}  //2021.11.08

                //2020.12.05
                policyTncVersion_PublishedDate={this.state.policyTncVersion_PublishedDate}
                Set_PolicyTncVersion_PublishedDate={this.Set_PolicyTncVersion_PublishedDate}

                //2020.12.11
                reEnterQuizRoom={this.state.reEnterQuizRoom}
                SetReEnterQuizRoom={this.SetReEnterQuizRoom}

                //2021.04.06
                CheckIfRoomBelongsToEvent={this.CheckIfRoomBelongsToEvent}

                //2021.04.12
                isFromApp_ParticipatedEvent={this.state.isFromApp_ParticipatedEvent}
                // SetIsFromAppParticipatedEvent={this.SetIsFromAppParticipatedEvent}

                //2021.4.13
                isDevMode={this.state.isDevMode}

                //2021.07.14
                OrganizerIdentity={this.state.OrganizerIdentity}
                // SetOrganizerIdentity={this.SetOrganizerIdentity}

                //2021.08.19
                Screen={Screen}
                SetScreen={this.SetScreen}

              // //2022.11.01
              // GenderOptions={this.state.GenderOptions}
              // RaceOptions={this.state.RaceOptions}
              />
            </Route>

            <Route path="/passwordReset" key={'K_PasswordResetScreen'}>
              <PasswordResetScreen
                key={'PasswordResetScreen'}

                Locale={this.state.Locale}
                SetLocaleSetting={this.SetLocaleSetting}

                SetLoading={this.SetLoading}
                SetAlert={this.SetAlert}
                SetAlertWithProgressBar={this.SetAlertWithProgressBar}
                CloseAlert={this.CloseAlert}

                //2020.11.30
                isInternetReachable={this.state.isInternetReachable}
                ShowInternetNotAvailableAlert={this.ShowInternetNotAvailableAlert}

                isDevMode={this.state.isDevMode}
              // email={this.state.email}
              />
            </Route>


            {/* 2020.11.26 - old signup disabled, future all follow new signup page (SignUpScreen_v2) */}
            {/* <Route path="/signup" key={'K_SignUpScreen'}>
                <SignUpScreen
                  key={'SignUpScreen'}

                  Locale={this.state.Locale}
                  SetLocaleSetting={this.SetLocaleSetting}

                  user={this.state.user}
                  email={this.state.email}

                  dbCommon={dbCommon}
                  firestore={firestore}
                  auth={auth}

                  SetAlert={this.SetAlert}
                  SetAlertWithProgressBar={this.SetAlertWithProgressBar}

                  login={this.handleLogin}
                  resetUserLogin={this.resetUserLogin}
                  newUser={this.newUserLogin}
                />
              </Route> */}


            <Route path="/blank" key={'K_BlankScreen'}>
              <BlankScreen
                key={'BlankScreen'}

                user={this.state.user}

                logout={this.handleLogout}
              />
            </Route>

            <Route path="/organizer/changePassword" key={'K_OrganizerChangePassword'} >
              <OrganizerChangePassword
                key={'OrganizerChangePassword'}

                Locale={this.state.Locale}
                SetLocaleSetting={this.SetLocaleSetting}

                email={this.state.email}
                password={this.state.password}
                user={this.state.user}

                SetLoading={this.SetLoading}
                SetAlert={this.SetAlert}
                CloseAlert={this.CloseAlert}

                isDevMode={this.state.isDevMode}
              />
            </Route>

            <Route path="/" key={'K_LoginScreen'} >
              <LoginScreen
                ref={this.LoginScreen_Ref}
                key={'LoginScreen'}

                dbCommon={dbCommon}
                firestore={firestore}

                Locale={this.state.Locale}
                SetLocaleSetting={this.SetLocaleSetting}

                LastUpdateDate={this.state.LastUpdateDate}
                PreviousUpdateDate={this.state.PreviousUpdateDate}    //2023.10.23
                isNewUpdate={this.state.IsNewUpdate}    //2021.07.27

                user={this.state.user}
                email={this.state.email}
                password={this.state.password}
                setEmailPassword={this.setEmailPassword}
                // SetSuperAdmin={this.SetSuperAdmin}
                setEmail={this.setEmailOnly}

                login={this.handleLogin}
                loginState={this.state.LoginState}
                setLoginState={this.SetLoginState}
                logout={this.handleLogout}
                error={this.handleSetError}
                SetErrorLog={this.SetErrorLog}

                Screen={Screen}
                SetScreen={this.SetScreen}

                Feedback={Feedback}
                SetLoading={this.SetLoading}
                SetAlert={this.SetAlert}
                SetAlertConfirm={this.SetAlertConfirm}
                SetAlertWithProgressBar={this.SetAlertWithProgressBar}

                //2020.11.26
                isFromParentApp={this.state.isFromParentApp}
                SetIsFromParentApp={this.SetIsFromParentApp}
                isRedirectToHistoryList={this.state.isRedirectToHistoryList}
                SetIsRedirectToHistoryList={this.SetIsRedirectToHistoryList}

                //2020.11.30
                isInternetReachable={this.state.isInternetReachable}
                ShowInternetNotAvailableAlert={this.ShowInternetNotAvailableAlert}

                //2020.12.03
                // Events={DefaultEvents}
                Events={useDefaultEvents ? DefaultEvents : this.state.EventList}  //2021.08.16
                SetIsEvent={this.SetIsEvent}
                SetIsUniqueEvent={this.SetIsUniqueEvent}    //2021.07.01
                EventNotAvailable={this.state.EventNotAvailable}    //2021.09.11
                IsActiveEventListLoaded={this.state.IsActiveEventListLoaded}  //2021.10.16
                SetEventNotAvailable={this.SetEventNotAvailable}  //2021.11.19
                SetEventInfo={this.SetEventInfo}  //2022.06.03

                //2021.04.12
                isFromApp_ParticipatedEvent={this.state.isFromApp_ParticipatedEvent}
                SetIsFromAppParticipatedEvent={this.SetIsFromAppParticipatedEvent}

                //2021.07.14
                OrganizerIdentity={this.state.OrganizerIdentity}
                SetOrganizerIdentity={this.SetOrganizerIdentity}

                //2021.07.27
                LoginAsAuthor={this.state.LoginAsAuthor}
                SetLoginAsAuthor={this.SetLoginAsAuthor}
                isDevMode={this.state.isDevMode}
                isSuperAdmin={this.state.isSuperAdmin}
                isAuthor={this.state.isAuthor}

              />
            </Route>

            {/* <Route path="/">
              <SplashScreen />
            </Route> */}

            {/* </Suspense> */}
          </Switch>

          {/* </div> */}
        </Router >

        <Alert
          // ref={this.alert}
          ref={alert => { this.alert = alert; }}
          key={'Alert'}
          Locale={this.state.Locale}

          Callback_AlertConfirm={this.Callback_AlertConfirm}
        />

        {
          this.state.timeLeftText !== null ?
            // <div class="floating-modal">
            //   {this.state.timeLeftText}
            // </div>
            <div
              className="floating-modal"
              dangerouslySetInnerHTML={{ __html: this.state.timeLeftText }}
              style={{ pointerEvents: 'none' }}
            />
            : null
        }

        {
          //=== 2021.04.08 - custom right-click popup menu ===
          // this.state.visible || this.state.visible === null ?
          //   <div ref={ref => { this.root = ref }} className="contextMenu">
          //     <span>&nbsp;&nbsp;Thank you for using iKEY Live Quiz.&nbsp;&nbsp;</span>
          //   </div>
          //   : null
        }

        {
          //2023.11.07 - internal use - admin tool.
          // <>
          //   <div className='form-control'>
          //     <input type='text' id='input-question-set-unique-id' disabled={this.state.admin_processing}></input>
          //     <button
          //       type='button'
          //       className='btn btn-primary'
          //       onclick={this.UpdateOwnershipForQuestionSetAndCorrespondingItems}
          //       disabled={this.state.admin_processing}
          //     >Run</button>
          //   </div>
          // </>
        }

        {
          // //2023.11.14 - internal use - admin tool.
          // <button
          //   type='button'
          //   className='btn btn-primary'
          //   onClick={this.UpdateProfileAndSyncToDB}
          //   disabled={this.state.admin_processing}
          //   style={{ zIndex: 999, position: 'fixed' }}
          // >UpdateProfileAndSyncToDB</button>
        }

        {
          // //2023.11.17 - internal use - admin tool.
          // <button
          //   type='button'
          //   className='btn btn-primary'
          //   onClick={this.UpdateOrganinerDetailsForQuizRoom}
          //   disabled={this.state.admin_processing}
          //   style={{ zIndex: 999, position: 'fixed' }}
          // >UpdateOrganinerDetailsForQuizRoom</button>
        }

      </div>
    )
  };
  // }
}

export default App;
